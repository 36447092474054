import React, { FormEvent, useState } from "react";
import styled from "styled-components";
import { theme } from "../theme/config";
import { useTranslation } from "react-i18next";
import { Text, Container, MainSection } from "../theme/shared";
import calculator from "../assets/images/calculator.png";
import calculatorMobile from "../assets/images/calculator_mobile.png";
import arrowWhite from "../assets/images/arrow-white.svg";
import closeWhite from "../assets/images/close_white.svg";
import employes from "../assets/images/employes.svg";
import car from "../assets/images/car.svg";
import boxIcon from "../assets/images/box_small.svg";
import bowl from "../assets/images/bowl.svg";

const StyledSection = styled(MainSection)`
  background: ${theme.colors.red.default};
  background-image: url(${calculator});
  background-repeat: no-repeat;
  background-position: bottom center;
  position: relative;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    background-image: url(${calculatorMobile});
  }
`;

const Row = styled.div`
  display: flex;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  width: 50%;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    width: 100%;
  }
`;

const StyledTitle = styled.h2`
  font-size: 3.75rem;
  line-height: 4.375rem;
  color: ${theme.colors.white.default};
  font-family: ${theme.fonts.headers};
  margin-bottom: 2.5rem;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    font-size: 1.438rem;
    line-height: 2.125rem;
    margin-bottom: 1.25rem;
  }
`;

const StyledText = styled(Text)`
  color: ${theme.colors.white.default};
  line-height: 1.563rem;
`;

const CalculatorContainer = styled(Container)`
  padding: 10rem 0 38.125rem;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    padding: 3.125rem 0 20.625rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
`;

const Button = styled.button`
  background: ${theme.colors.orange.default};
  border-radius: 39px;
  height: 78px;
  width: 196px;
  font-size: 1.563rem;
  color: ${theme.colors.green.default};
  border: 0;
  margin: 10px auto 0;
  position: relative;
  cursor: pointer;
  &:after {
    background: url(${arrowWhite});
    width: 156px;
    height: 174px;
    content: " ";
    position: absolute;
    left: -50px;
    bottom: -174px;
    @media (max-width: ${theme.breakpoints.tablet.max}) {
      background-size: 65%;
      left: -30px;
      background-repeat: no-repeat;
    }
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    height: 46px;
    width: 145px;
    font-size: 0.938rem;
  }
`;

const Label = styled.label`
  font-size: 1.5rem;
  line-height: 2.375rem;
  color: ${theme.colors.white.default};
  width: 100%;
  text-align: center;
  margin-bottom: 10px;

  @media (max-width: ${theme.breakpoints.mobile.max}) {
    font-size: 0.75rem;
  }
`;

const Input = styled.input`
  height: 64px;
  background: transparent;
  border: 1px solid ${theme.colors.white.default};
  border-radius: 14px;
  width: 100%;
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: ${theme.colors.white.default};
  padding-left: 15px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    height: 47px;
  }
`;

const FormColumn = styled.div`
  width: 360px;
  max-width: 100%;
  display: flex;
  text-align: center;
  margin: auto;
`;

const ResultModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(245, 135, 199, 0.81);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalBox = styled.div`
  width: 1140px;
  padding: 44px 140px 140px;
  background: ${theme.colors.green.default};
  position: relative;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    width: calc(100% - 80px);
    padding: 44px 40px 80px;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    padding: 44px 40px 0;
  }
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    width: calc(100% - 80px);
    margin: 0 auto;
    padding: 80px 20px 40px 0;
  }
`;

const ModalHeader = styled.h2`
  color: ${theme.colors.white.default};
  font-family: ${theme.fonts.headers};
  font-size: 3.75rem;
  line-height: 4.375rem;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    display: none;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 2.5rem;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    margin-bottom: 40px;
  }
`;

const ModalRow = styled.div`
  display: flex;
  margin-top: 115px;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    flex-direction: column;
    margin-top: 0;
    padding: 0 20px 0 40px;
  }

  @media (max-width: ${theme.breakpoints.tablet.max}) {
    margin-top: 0;
  }
`;
const ModalItem = styled.div`
  color: ${theme.colors.white.default};
  width: 50%;
  font-size: 1.875rem;
  line-height: 2.063rem;
  padding-left: 15px;
  min-height: 71px;
  background-position: left center;

  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 1rem;
    line-height: 1.313rem;
    width: 100%;
  }
`;
const ModalItemEmployess = styled(ModalItem)`
  background: url(${employes}) no-repeat;
  padding-left: 123px;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    background-size: 50px 41px;
    margin-bottom: 40px;
    padding-left: 105px;
  }
`;
const ModalItemBox = styled(ModalItem)`
  background: url(${boxIcon}) no-repeat;
  padding-left: 123px;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    background-size: 81px 46px;
    margin-bottom: 40px;
    padding-left: 105px;
  }
`;
const ModalItemDelivery = styled(ModalItem)`
  background: url(${car}) no-repeat;
  padding-left: 160px;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    background-size: 88px 45px;
    margin-bottom: 40px;
    padding-left: 105px;
  }
`;
const ModalItemBowl = styled(ModalItem)`
  background: url(${bowl}) no-repeat;
  padding-left: 160px;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    background-size: 72px 38px;
    padding-left: 105px;
  }
`;
const Result = styled.span`
  color: ${theme.colors.orange.default};
`;

const ModalClose = styled.div`
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 40px;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    top: 20px;
    right: 20px;
    img {
      width: 36px;
      height: 36px;
    }
  }
`;

function Calculator() {
  const { t } = useTranslation();
  const [employs, setEmploys] = useState("");
  const [deliveries, setDeliveries] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [result, setResult] = useState({
    portion: 0,
    size: 0,
  });

  const calculate = (event: FormEvent) => {
    event.preventDefault();
    let portion = 0;
    let size = 0;
    if (employs && deliveries) {
      if (parseInt(deliveries) <= 3) {
        portion = parseInt(employs) * 2;
        size = 5 * Math.round(Math.ceil((portion * 0.25) / 5));
      } else if (parseInt(deliveries) <= 5) {
        portion = parseInt(employs);
        size = 5 * Math.round(Math.ceil((portion * 0.25) / 5));
      }
    }
    if (portion && size) {
      setResult({
        portion: portion,
        size: size,
      });
      setShowModal(true);
    }
  };

  const resultModal = (
    <ResultModal>
      <ModalBox>
        <ModalHeader>sugerowana ilość:</ModalHeader>
        <ModalClose onClick={() => setShowModal(false)}>
          <img src={closeWhite} alt={t("close")} />
        </ModalClose>
        <ModalRow>
          <ModalItemEmployess>
            {t("liczba")}
            <br />
            {t("pracowników")}: <Result>{employs}</Result>
          </ModalItemEmployess>
          <ModalItemDelivery>
            {t("ilość dostaw")}
            <br />
            {t("w tygodniu")}: <Result>{deliveries}</Result>
          </ModalItemDelivery>
        </ModalRow>
        <ModalRow>
          <ModalItemBox>
            {t("waga")}
            <br />
            {t("owoców")}: <Result>{result.size} kg</Result>
          </ModalItemBox>
          <ModalItemBowl>
            {t("ilość")}
            <br />
            {t("porcji")}: <Result>{result.portion}</Result>
          </ModalItemBowl>
        </ModalRow>
      </ModalBox>
    </ResultModal>
  );

  return (
    <StyledSection id={"calculator"}>
      {showModal && resultModal}
      <CalculatorContainer>
        <Row>
          <Column>
            <StyledTitle>{t("ile owoców potrzebuje twoja firma?")}</StyledTitle>
            <StyledText>
              {t("Wpisz ile osób zatrudnia Twoja firma oraz ilość")}
              <br />
              {t("dostaw w tygodniu. Te dane są wykorzystywane")}
              <br />
              {t("przez nas tylko na potrzeby wyceny.")}
            </StyledText>
          </Column>
          <FormColumn>
            <form onSubmit={calculate}>
              <Label>{t("liczba pracowników:")}</Label>
              <Input
                type={"number"}
                onChange={(e) => setEmploys(e.target.value)}
                value={employs}
              />
              <Label>{t("ilość dostaw w tygodniu:")}</Label>
              <Input
                type={"number"}
                max={5}
                onChange={(e) => setDeliveries(e.target.value)}
                value={deliveries}
              />
              <Button type={"submit"}>{t("oblicz")}</Button>
            </form>
          </FormColumn>
        </Row>
      </CalculatorContainer>
    </StyledSection>
  );
}

export default Calculator;
