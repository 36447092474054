import React, { useEffect } from "react";
import { RouteObject, useRoutes } from "react-router-dom";
import Home from "./home";
import NotFound from "./notFound";
import Policy from "./policy";
import { ScrollingProvider } from "react-scroll-section";
import Footer from "./components/footer";
import Menu from "./components/menu";

export default function App() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src =
      "https://cdn-cookieyes.com/client_data/1ee8b827716611ae2a7c2e48/script.js";

    script.id = "cookieyes";
    document.head.prepend(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const routes: RouteObject[] = [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "polityka-prywatnosci/",
      element: <Policy />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ];
  const element = useRoutes(routes);

  return (
    <div className="App">
      <ScrollingProvider>
        <Menu />
        {element}
        <Footer />
      </ScrollingProvider>
    </div>
  );
}
