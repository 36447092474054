import React from "react";
import styled from "styled-components";
import { theme } from "../theme/config";
import { useTranslation } from "react-i18next";
import { Header, Title, Text, Container, MainSection } from "../theme/shared";
import order from "../assets/images/order.svg";
import pack from "../assets/images/pack.svg";
import delivery from "../assets/images/delivery.svg";
import payment from "../assets/images/payment.svg";

const StyledSection = styled(MainSection)`
  background: ${theme.colors.green.default};
  @media (min-width: ${theme.breakpoints.tablet.min}) and (max-width: ${theme
      .breakpoints.tablet.max}) {
    padding-top: 60px;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    padding-bottom: 30px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    flex-wrap: wrap;
  }
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    width: 50%;
  }
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    width: 100%;
  }
`;

const StyledTitle = styled(Title)`
  color: ${theme.colors.white.default};
  margin-bottom: 75px;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
`;

const StyledHeader = styled(Header)`
  line-height: 1.75rem;
  color: ${theme.colors.white.default};
  margin-bottom: 1.25rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 0.938rem;
    line-height: 1.375rem;
    margin-bottom: 0;
  }
`;

const StyledText = styled(Text)`
  color: ${theme.colors.white.default};
  font-size: 1rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 0.625rem;
    line-height: 0.938rem;
    margin-bottom: 0px;
  }
`;

const HowItWorksContainer = styled(Container)`
  padding: 150px 0 8.125rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    padding: 0;
    margin-top: -20px;
  }
`;

const Icon = styled.div`
  margin-bottom: 3.75rem;
  height: 150px;
  display: flex;
  img {
    max-width: 100%;
    margin-top: auto;
  }
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    width: 190px;
    padding-right: 2rem;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    width: 140px;
    height: 130px;
    margin-bottom: 0;
    padding-right: 0;
    img {
      margin-top: 0;
    }
  }
`;

const IconOrder = styled(Icon)`
  img {
    margin-bottom: 0px;
    @media (max-width: ${theme.breakpoints.tablet.max}) {
      width: 100px;
      margin-top: 20px;
    }
  }
`;

const IconDelivery = styled(Icon)`
  img {
    margin-bottom: 0px;
    @media (max-width: ${theme.breakpoints.tablet.max}) {
      width: 110px;
      margin-top: 30px;
    }
  }
`;

const IconPack = styled(Icon)`
  img {
    margin-bottom: 10px;
    @media (max-width: ${theme.breakpoints.tablet.max}) {
      width: 93px;
      margin-top: 30px;
    }
  }
`;

const IconPay = styled(Icon)`
  img {
    margin-bottom: 10px;
    @media (max-width: ${theme.breakpoints.tablet.max}) {
      width: 97px;
      margin-top: 30px;
      margin-left: 20px;
    }
  }
`;

const Item = styled.div`
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    margin-bottom: 0;
  }
`;

function HowItWorks() {
  const { t } = useTranslation();
  return (
    <StyledSection id={"howItWorks"}>
      <HowItWorksContainer>
        <StyledTitle>{t("jak to działa?")}</StyledTitle>
        <Row>
          <Column>
            <IconOrder>
              <img src={order} alt={t("Order")} />
            </IconOrder>
            <Item>
              <StyledHeader>{t("zamówienie")}</StyledHeader>
              <StyledText>
                {t("Zamówienia na dostawy")}
                <br />
                {t("przyjmujemy telefonicznie")}
                <br />
                {t("i mailowo od poniedziałku")}
                <br />
                {t("do piątku w godzinach")}
                <br />
                {t("9:00—15:00.")}
              </StyledText>
            </Item>
          </Column>
          <Column>
            <IconPack>
              <img src={pack} alt={t("Pack")} />
            </IconPack>
            <Item>
              <StyledHeader>{t("pakowanie")}</StyledHeader>
              <StyledText>
                {t("Nasze owoce są selekcjonowane w noc")}
                <br />
                {t("poprzedzającą dzień dostaw i starannie")}
                <br />
                {t("układane przez pracowników magazynu")}
                <br />
                {t("do specjalnie zaprojektowanych,")}
                <br />
                {t("wymiennych, drewnianych skrzynek.")}
              </StyledText>
            </Item>
          </Column>
          <Column>
            <IconDelivery>
              <img src={delivery} alt={t("Delivery")} />
            </IconDelivery>
            <Item>
              <StyledHeader>{t("dostawa")}</StyledHeader>
              <StyledText>
                {t("Owoce do biur dostarczane są")}
                <br />
                {t("w wybrane przez klienta dni,")}
                <br />
                {t("od poniedziałku do piątku")}
                <br />
                {t("w godzinach 6:00—13:00.")}
              </StyledText>
            </Item>
          </Column>
          <Column>
            <IconPay>
              <img src={payment} alt={t("payment")} />
            </IconPay>
            <Item>
              <StyledHeader>{t("płatności")}</StyledHeader>
              <StyledText>
                {t("Wystawiamy jedną, zbiorczą")}
                <br />
                {t("fakturę z dogodnym terminem")}
                <br />
                {t("płatności, zawsze ostatniego")}
                <br />
                {t("dnia miesiąca.")}
              </StyledText>
            </Item>
          </Column>
        </Row>
      </HowItWorksContainer>
    </StyledSection>
  );
}

export default HowItWorks;
