import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { theme } from "../theme/config";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logo.svg";
import logoMobile from "../assets/images/logoMobile.svg";
import closeGreenDark from "../assets/images/closeGreenDark.svg";
import hamburger from "../assets/images/hamburger.svg";
import arrowLeft from "../assets/images/arrow-left.svg";
import { useScrollSection } from "react-scroll-section";
import { Container } from "../theme/shared";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Section = styled.div`
  background: ${theme.colors.green.default};
`;

const MenuContainer = styled(Container)`
  display: flex;
  padding: 50px 2.5rem;
  box-sizing: border-box;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    padding: 40px 2.5rem;
  }
`;

const StyledLink = styled.a<{ noMargin?: boolean }>`
  font-family: ${theme.fonts.headers};
  color: ${theme.colors.white.default};
  margin-left: ${(props) => (props.noMargin ? 0 : "2.188rem")};
  font-size: 1.563rem;
  cursor: pointer;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    color: ${theme.colors.green.default};
  }
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    font-size: 1.125rem;
    margin-left: ${(props) => (props.noMargin ? 0 : "1.7rem")};
  }
  img {
    vertical-align: middle;
    margin-left: 5px;
  }
`;

const LanguageLink = styled.a`
  font-family: ${theme.fonts.headers};
  color: ${theme.colors.orange.default};
  margin-left: 2.188rem;
  font-size: 1.563rem;
  cursor: pointer;
`;

const Logo = styled.div`
  @media (min-width: 1800px) {
    margin-left: -140px;
  }

  @media (max-width: ${theme.breakpoints.tablet.max}) {
    img {
      margin-top: -19px;
      margin-left: -19px;
    }
  }
`;

const ImgMobile = styled.img`
  display: none;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    display: block;
  }
`;

const ImgDesktop = styled.img`
  display: block;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    display: none;
  }
`;

const LanguageSection = styled.div`
  //display: flex;
  padding: 25px 0 0;
  display: none;
`;
const MenuSection = styled.div<{ menuOpen: boolean }>`
  display: flex;
  padding: 25px 0 0;
  margin-left: auto;
  max-width: ${(props) => (props.menuOpen ? "100%" : "90px")};
  overflow: hidden;
  white-space: nowrap;
  transition: max-width 1s ease-in-out;
  span {
    opacity: ${(props) => (props.menuOpen ? "0" : "1")};
    transition: opacity 1s ease-in-out;
    transition-delay: 150ms;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    padding: 5rem 0 0 0;
    width: 100%;
    max-width: ${(props) => (props.menuOpen ? "100%" : "0")};
    position: fixed;
    height: 100vh;
    right: 0;
    top: 0;
    background: ${theme.colors.orange.default};
    z-index: 9;
    overflow: auto;
    flex-direction: column;
  }
`;

const ButtonColumnSmall = styled.div`
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    position: fixed;
    top: 1.438rem;
    right: 2.313rem;
    width: 27px;
    height: 19px;
    background: url(${hamburger}) no-repeat;
    background-size: 100%;
    text-indent: -99999999px;
    cursor: pointer;
    z-index: 8;
  }
`;
const ButtonColumn = styled.div`
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    position: absolute;
    top: 1.438rem;
    right: 2.313rem;
    width: 36px;
    height: 36px;
    background: url(${closeGreenDark}) no-repeat;
    background-size: 100%;
    text-indent: -99999999px;
    z-index: 9;
    cursor: pointer;
  }
`;

const Column = styled.div`
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    margin-bottom: 1.125rem;
  }
`;

function Menu() {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const homeSection = useScrollSection("home");
  const calculatorSection = useScrollSection("calculator");
  const orderSection = useScrollSection("orderForm");
  const benefitsSection = useScrollSection("benefits");
  const boxSection = useScrollSection("boxes");
  const missionSection = useScrollSection("mission");
  const sendGiftSection = useScrollSection("ourBrands");
  const contactSection = useScrollSection("contact");

  const [openPage, setOpenPageCallback] = useState(() => () => {
    return;
  });

  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      openPage();
    }, 200);
  }, [location]);

  const goToSection = (callback: () => void) => {
    if (location.pathname !== "/") {
      setOpenPageCallback(() => () => callback());
      navigate("/");
    } else {
      callback();
    }

    setMenuOpen(false);
  };

  const toggleLanguage = () => {
    let language;
    if (i18n.language == "en-US") {
      language = "pl-PL";
    } else {
      language = "en-US";
    }
    i18n.changeLanguage(language).catch((error) => {
      console.log(error);
    });
  };

  return (
    <Section>
      <MenuContainer>
        <Logo>
          <Link to={"/"} onClick={homeSection.onClick}>
            <ImgDesktop src={logo} alt={"owocedobiura.pl"} />
            <ImgMobile src={logoMobile} alt={"owocedobiura.pl"} />
          </Link>
        </Logo>
        <MenuSection menuOpen={menuOpen}>
          <ButtonColumn onClick={() => setMenuOpen(!menuOpen)}>
            <StyledLink onClick={() => setMenuOpen(!menuOpen)} noMargin={true}>
              <span>menu</span> <img src={arrowLeft} alt={t("Toggle menu")} />
            </StyledLink>
          </ButtonColumn>
          <Column>
            <StyledLink onClick={() => goToSection(benefitsSection.onClick)}>
              {t("benefits")}
            </StyledLink>
          </Column>
          <Column>
            <StyledLink onClick={() => goToSection(calculatorSection.onClick)}>
              {t("kalkulator")}
            </StyledLink>
          </Column>
          <Column>
            <StyledLink onClick={() => goToSection(orderSection.onClick)}>
              {t("zamówienie")}
            </StyledLink>
          </Column>
          <Column>
            <StyledLink onClick={() => goToSection(boxSection.onClick)}>
              {t("skrzynki")}
            </StyledLink>
          </Column>
          <Column>
            <StyledLink onClick={() => goToSection(missionSection.onClick)}>
              {t("misja")}
            </StyledLink>
          </Column>
          <Column>
            <StyledLink onClick={() => goToSection(sendGiftSection.onClick)}>
              {t("sendgift")}
            </StyledLink>
          </Column>
          <Column>
            <StyledLink onClick={() => goToSection(contactSection.onClick)}>
              {t("kontakt")}
            </StyledLink>
          </Column>
        </MenuSection>
        <ButtonColumnSmall onClick={() => setMenuOpen(!menuOpen)} />
        <LanguageSection>
          <LanguageLink onClick={() => toggleLanguage()}>
            {i18n.language == "en-US" ? "PL" : "EN"}
          </LanguageLink>
        </LanguageSection>
      </MenuContainer>
    </Section>
  );
}

export default Menu;
