import React, { FormEvent, useState } from "react";
import styled from "styled-components";
import { theme } from "../theme/config";
import { useTranslation } from "react-i18next";
import { Container, Header, MainSection } from "../theme/shared";
import orderHero from "../assets/images/order_hero.jpg";
import arrowGreen from "../assets/images/arrow-green.svg";
import axios from "axios";
import closeWhite from "../assets/images/close_white.svg";
import closeGreen from "../assets/images/closeGreenDark.svg";
import successBg from "../assets/images/success_bg.png";
import successBgMobile from "../assets/images/success_mobile.png";
import errorBg from "../assets/images/error_bg.png";
import Select from "react-select";
import { Link } from "react-router-dom";

const StyledSection = styled(MainSection)`
  background: ${theme.colors.orange.default};
  position: relative;
`;

const Column = styled.div`
  width: 50%;
`;

const ImageColumn = styled(Column)`
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    display: none;
  }
`;

const FormColumn = styled.div`
  width: 50%;
  padding: 0 70px 0 145px;
  text-align: center;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    padding: 0 25px 0 45px;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    width: 100%;
    padding: 0;
  }
`;

const StyledHeader = styled(Header)`
  line-height: 4.375rem;
  font-size: 3.75rem;
  color: ${theme.colors.green.default};
  text-align: left;
  margin-bottom: 2.813rem;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    font-size: 1.438rem;
    margin-bottom: 1.75rem;
    line-height: 2.125rem;
  }
`;

const OrderContainer = styled(Container)`
  padding: 6.875rem 0;
  display: flex;
  justify-content: space-between;
  img {
    width: 100%;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    padding: 3.625rem 0 6.25rem;
  }
`;

const StyledInput = styled.input`
  border-radius: 14px;
  font-family: ${theme.fonts.default};
  border: 2px solid ${theme.colors.green.default};
  color: ${theme.colors.green.default};
  width: 100%;
  background: transparent;
  font-size: 1.063rem;
  height: 4rem;
  padding-left: 1.563rem;
  padding-right: 1.563rem;
  margin-bottom: 1.438rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    height: 2.938rem;
    font-size: 0.75rem;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
    margin-bottom: 0.75rem;
  }
  ::placeholder {
    color: ${theme.colors.green.default};
  }
`;

const StyledTextarea = styled.textarea`
  border-radius: 14px;
  font-family: ${theme.fonts.default};
  border: 2px solid ${theme.colors.green.default};
  color: ${theme.colors.green.default};
  width: 100%;
  background: transparent;
  font-size: 1.063rem;
  height: 8rem;
  padding-top: 1rem;
  padding-left: 1.563rem;
  padding-right: 1.563rem;
  margin-bottom: 1.438rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    height: 2.938rem;
    font-size: 0.75rem;
    padding-left: 1.375rem;
    padding-right: 1.375rem;
    margin-bottom: 0.75rem;
  }
  ::placeholder {
    color: ${theme.colors.green.default};
  }
`;

const RequiredFieldsLabel = styled.div`
  text-align: right;
  color: ${theme.colors.green.default};
  font-size: 0.75rem;
  margin-bottom: 1.438rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    margin-bottom: 0.75rem;
  }
`;

const StyledCheckbox = styled.div`
  text-align: left;
  margin-bottom: 1.438rem;
  line-height: 1.25;
  font-size: 1.063rem;
  vertical-align: center;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    margin-bottom: 0.75rem;
    font-size: 1rem;
  }
  a {
    color: ${theme.colors.green.default};
  }
`;

const StyledCheckboxButton = styled.div<{ selected?: boolean }>`
  border: 2px solid ${theme.colors.green.default};
  ${(props) => props.selected && `background: ${theme.colors.green.default};`}
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
  transition: all 300ms ease-in;
`;

const StyledSelect = styled(Select)`
  .css-13cymwt-control,
  .css-t3ipsp-control {
    border-radius: 14px;
    font-family: ${theme.fonts.default};
    border: 2px solid ${theme.colors.green.default};
    color: ${theme.colors.green.default};
    width: 100%;
    background: transparent;
    font-size: 1.063rem;
    height: 4rem;
    padding-left: 1.563rem;
    padding-right: 0;
    margin-bottom: 1.438rem;
    text-align: left;
    cursor: pointer;
    @media (max-width: ${theme.breakpoints.tablet.max}) {
      height: 2.938rem;
      font-size: 0.75rem;
      padding-left: 1.375rem;
      padding-right: 0;
      margin-bottom: 0.75rem;
    }
  }
  .css-13cymwt-control:hover,
  .css-t3ipsp-control:hover {
    border: 2px solid ${theme.colors.green.default};
  }
  .css-1dimb5e-singleValue,
  .css-1xc3v61-indicatorContainer,
  .css-1xc3v61-indicatorContainer:hover,
  .css-1jqq78o-placeholder {
    color: ${theme.colors.green.default};
  }
  .css-1u9des2-indicatorSeparator {
    background-color: ${theme.colors.green.default};
  }
  .css-1nmdiq5-menu {
    text-align: left;
  }
  .css-1fdsijx-ValueContainer {
    padding-left: 0;
  }
  .css-10wo9uf-option,
  .css-d7l1ni-option,
  .css-tr4s17-option {
    color: ${theme.colors.green.default};
    font-size: 1.063rem;
    padding-left: 1.563rem;
    padding-right: 0;
    @media (max-width: ${theme.breakpoints.tablet.max}) {
      font-size: 0.75rem;
      padding-left: 1.375rem;
    }
  }
  .css-d7l1ni-option,
  .css-tr4s17-option {
    background: ${theme.colors.green.default};
    color: #fff;
  }
`;

const StyledButton = styled.button`
  background: ${theme.colors.green.default};
  color: ${theme.colors.orange.default};
  border-radius: 39px;
  font-size: 1.563rem;
  width: 310px;
  height: 78px;
  border: 0;
  position: relative;
  cursor: pointer;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    margin-top: 1.25rem;
    font-size: 0.938rem;
    height: 2.813rem;
    width: 12.125rem;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:after {
    background: url(${arrowGreen});
    width: 154px;
    height: 174px;
    content: " ";
    position: absolute;
    left: 0;
    bottom: -174px;
    @media (max-width: ${theme.breakpoints.tablet.max}) {
      background-size: 90px auto;
      background-repeat: no-repeat;
      width: 90px;
      height: 94px;
      bottom: -90px;
      left: 20px;
    }
  }
`;

const ResultModal = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(139, 224, 118, 0.81);
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    width: 100%;
    padding: 20px 40px;
    box-sizing: border-box;
  }
`;

const ModalBox = styled.div<{ success: boolean }>`
  width: 1139px;
  padding: 30px 30px 350px;
  position: relative;
  background: url(${(props) => (props.success ? successBg : errorBg)}) no-repeat
    bottom right;
  background-color: ${(props) =>
    props.success ? theme.colors.pink.default : theme.colors.red.default};

  @media (max-width: ${theme.breakpoints.tablet.max}) {
    width: 100%;
    margin-top: 0;
    height: calc(100% - 40px);
    background-image: url(${(props) =>
      props.success ? successBgMobile : errorBg});
    background-position: ${(props) =>
      props.success ? "bottom center" : "bottom right"};
    background-size: ${(props) => (props.success ? "auto" : "90%")};
]  }
`;

const ModalHeader = styled.h2<{ success: boolean }>`
  font-family: ${theme.fonts.headers};
  font-size: 3.75rem;
  line-height: 4.375rem;
  margin-bottom: 40px;
  color: ${(props) =>
    props.success ? theme.colors.green.default : theme.colors.white.default};

  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 1.625rem;
    line-height: 2.375rem;
    margin-bottom: 0.938rem;
  }
`;

const ModalText = styled.p<{ success: boolean }>`
  font-size: 1.563rem;
  line-height: 2.188rem;
  color: ${(props) =>
    props.success ? theme.colors.green.default : theme.colors.white.default};
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 1rem;
    line-height: 1.438rem;
  }
`;

const ModalClose = styled.div`
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 50px;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    top: 20px;
    right: 20px;
    img {
      width: 36px;
      height: 36px;
    }
  }
`;

function OrderForm() {
  const { t } = useTranslation();

  const [company, setCompany] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [employess, setEmployess] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [sendStatus, setSendStatus] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [message, setMessage] = useState("");

  const send = (event: FormEvent) => {
    event.preventDefault();
    const formData = {
      company,
      name,
      email,
      phone,
      city,
      employess,
      policy,
      marketing,
      message,
    };
    axios
      .post("https://api.owocedobiura.pl/api_external/site/order/", formData)
      .then(() => {
        setShowModal(true);
        setSendStatus(true);
      })
      .catch(() => {
        setShowModal(true);
        setSendStatus(false);
      });
  };

  const employessOptions = [
    { value: "0-50", label: "Liczba pracowników: 0-50" },
    { value: "51-100", label: "Liczba pracowników: 51-100" },
    { value: "101-200", label: "Liczba pracowników: 101-200" },
    { value: "201-500", label: "Liczba pracowników: 201-500" },
    { value: "501-1000", label: "Liczba pracowników: 501-1000" },
    { value: "1000+", label: "Liczba pracowników: 1000+" },
  ];

  const resultModal = (
    <ResultModal>
      <ModalBox success={sendStatus}>
        <ModalClose onClick={() => setShowModal(false)}>
          <img src={sendStatus ? closeGreen : closeWhite} alt={t("close")} />
        </ModalClose>
        {sendStatus && (
          <>
            <ModalHeader success={sendStatus}>
              {t("zapytanie złożone")}
              <br />
              {t("pomyślnie!")}
            </ModalHeader>
            <ModalText success={sendStatus}>
              {t("Wkrótce odezwiemy")}
              <br />
              {t("się z odpowiedzią.")}
            </ModalText>
          </>
        )}
        {!sendStatus && (
          <>
            <ModalHeader success={sendStatus}>
              {t("niestety nie udało")}
              <br />
              {t("się złożyć zapytania.")}
            </ModalHeader>
            <ModalText success={sendStatus}>{t("spróbuj ponownie")}</ModalText>
          </>
        )}
      </ModalBox>
    </ResultModal>
  );

  return (
    <StyledSection id={"orderForm"}>
      {showModal && resultModal}
      <OrderContainer>
        <ImageColumn>
          <img src={orderHero} alt={t("Order")} />
        </ImageColumn>
        <FormColumn>
          <StyledHeader>{t("złóż zapytanie ofertowe")}</StyledHeader>
          <form onSubmit={send}>
            <StyledInput
              type="text"
              placeholder={t("nazwa firmy*")}
              onChange={(e) => setCompany(e.target.value)}
              value={company}
            />
            <StyledInput
              type="text"
              placeholder={t("imię i nazwisko*")}
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
            <StyledInput
              type="text"
              placeholder={t("e-mail*")}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <StyledInput
              type="text"
              placeholder={t("telefon*")}
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
            <StyledInput
              type="text"
              placeholder={t("miasto*")}
              onChange={(e) => setCity(e.target.value)}
              value={city}
            />
            <StyledSelect
              onChange={(newValue: any) => setEmployess(newValue?.value || "")}
              options={employessOptions}
              placeholder={"Wybierz liczbę pracowników*"}
            />
            <StyledTextarea
              placeholder={t("Twoja wiadomość...")}
              onChange={(e) => setMessage(e.target.value)}
            >
              {message}
            </StyledTextarea>
            <RequiredFieldsLabel>{t("* Pola wymagane")}</RequiredFieldsLabel>
            <StyledCheckbox>
              <StyledCheckboxButton
                selected={policy}
                onClick={() => setPolicy(!policy)}
              />
              Zapoznałem się z{" "}
              <Link to={"/polityka-prywatnosci"} target={"_blank"}>
                polityką prywatności
              </Link>
              *
            </StyledCheckbox>
            <StyledCheckbox>
              <StyledCheckboxButton
                selected={marketing}
                onClick={() => setMarketing(!marketing)}
              />
              Wyrażam zgodę na przetwarzanie moich danych osobowych w celach
              marketingowych, poprzez przesyłanie informacji za pomocą poczty
              elektronicznej, na podany adres e-mail
            </StyledCheckbox>

            <StyledButton
              type={"submit"}
              disabled={
                !company ||
                !name ||
                !email ||
                !phone ||
                !city ||
                !employess ||
                !policy
              }
            >
              {t("wyślij zapytanie")}
            </StyledButton>
          </form>
        </FormColumn>
      </OrderContainer>
    </StyledSection>
  );
}

export default OrderForm;
