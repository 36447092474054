import React from "react";
import styled from "styled-components";
import { theme } from "../theme/config";
import { Container, MainSection, Text } from "../theme/shared";
import sendGift from "../assets/images/send_gift.png";
import sendgiftMan from "../assets/images/sendgift-man.jpg";
import { useTranslation } from "react-i18next";

const StyledSection = styled(MainSection)`
  background: ${theme.colors.white.default};
`;

const OurBrandContainer = styled(Container)`
  max-width: 1024px;
  background-image: url(${sendgiftMan});
  background-position: center right;
  background-repeat: no-repeat;
  position: relative;
  padding: 5.625rem 0 5.625rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    max-width: 720px;
  }
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    background-image: none;
    max-width: 100%;
    text-align: center;
  }
`;

const StyledText = styled(Text)`
  margin-top: 40px;
  line-height: 1.563rem;
  color: ${theme.colors.black.default};
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 0.75rem;
    margin-top: 3.438rem;
    line-height: 1.125rem;
    margin-bottom: 0;
  }
`;

const Button = styled.a`
  color: ${theme.colors.white.default};
  font-family: ${theme.fonts.headers};
  background: ${theme.colors.red.sendgift};
  text-decoration: none;
  font-size: 1.25rem;
  line-height: 1;
  padding: 1.25rem 2.813rem;
  border-radius: 39px;
  display: inline-block;
  margin-top: 1.875rem;
  margin-left: -1.063rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 0.938rem;
    padding: 0.875rem 2.875rem;
    margin-top: 3.125rem;
    margin-left: 0;
  }
`;

const StyledLogo = styled.div`
  img {
    height: 100px;
  }
`;

function OurBrands() {
  const { t } = useTranslation();
  return (
    <StyledSection id={"ourBrands"}>
      <OurBrandContainer>
        <StyledLogo>
          <img src={sendGift} alt="sendgift.pl" />
        </StyledLogo>
        <StyledText>
          {t("Szukasz paczki dla swoich pracowników")} <br />
          {t("na specjalną okazję?")} <br />
          {t("Sprawdź Sendgift.pl")} <br />
        </StyledText>
        <Button href="https://sendgift.pl/">{t("sprawdź")}</Button>
      </OurBrandContainer>
    </StyledSection>
  );
}

export default OurBrands;
