import React from "react";
import styled from "styled-components";
import { theme } from "../theme/config";
import { useTranslation } from "react-i18next";
import { Container, MainSection, Title } from "../theme/shared";
import box from "../assets/images/box.png";
import arrowBox1 from "../assets/images/arrow-box-1.svg";
import arrowBox2 from "../assets/images/arrow-box-2.svg";
import arrowBox3 from "../assets/images/arrow-box-3.svg";
import arrowBox4 from "../assets/images/arrow-box-4.svg";
import arrowBox1Mobile from "../assets/images/arrow-box-1-mobile.svg";
import arrowBox2Mobile from "../assets/images/arrow-box-2-mobile.svg";
import arrowBox3Mobile from "../assets/images/arrow-box-3-mobile.svg";
import arrowBox4Mobile from "../assets/images/arrow-box-4-mobile.svg";

const StyledSection = styled(MainSection)`
  background: ${theme.colors.white.default};
`;

const StyledTitle = styled(Title)`
  color: ${theme.colors.green.default};
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 1.438rem;
  }
`;

const BoxesContainer = styled(Container)`
  padding-top: 4.375rem;
  padding-bottom: 55px;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    padding-left: 0;
    padding-top: 3.75rem;
    padding-bottom: 0;
  }
`;

const Image = styled.div`
  text-align: center;
  width: 100%;
  position: relative;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    img {
      max-width: 400px;
    }
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
    max-width: 340px;
    margin: 0 auto;
    img {
      max-width: 15.813rem;
    }
  }
`;
const Description = styled.div`
  font-size: 1.563rem;
  line-height: 2.188rem;
  position: absolute;
  text-align: left;
  color: ${theme.colors.green.default};
  font-family: ${theme.fonts.headers};
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: ${theme
      .breakpoints.desktop.max}) {
    font-size: 1.376rem;
    line-height: 1.563rem;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  &:after {
    content: " ";
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
`;
const DescriptionOne = styled(Description)`
  top: 350px;
  left: 0;
  width: 270px;
  &:after {
    width: 306px;
    height: 180px;
    top: -150px;
    left: 120px;
    background-image: url(${arrowBox1});
  }
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: ${theme
      .breakpoints.desktop.max}) {
    top: 180px;
    left: 80px;
    &:after {
      width: 200px;
      left: 120px;
    }
  }
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: 1200px) {
    left: 20px;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    top: 50px;
    right: 0;
    left: auto;
    width: 120px;
    text-align: center;
    &:after {
      width: 103px;
      height: 132px;
      top: 45px;
      left: 60px;
      background-image: url(${arrowBox2Mobile});
    }
  }
`;

const DescriptionTwo = styled(Description)`
  top: 730px;
  left: 60px;
  width: 230px;
  &:after {
    top: -80px;
    left: 180px;
    width: 172px;
    height: 159px;
    background-image: url(${arrowBox2});
  }
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: ${theme
      .breakpoints.desktop.max}) {
    top: 450px;
    left: 100px;
    &:after {
      width: 140px;
    }
  }
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: 1200px) {
    left: 40px;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    top: 4.688rem;
    width: 100px;
    left: 0;
    &:after {
      width: 75px;
      height: 61px;
      top: 40px;
      left: 40px;
      background-image: url(${arrowBox1Mobile});
    }
  }
`;

const DescriptionThree = styled(Description)`
  top: 230px;
  right: 0;
  width: 280px;
  &:after {
    left: -200px;
    top: -35px;
    width: 173px;
    height: 100px;
    background-image: url(${arrowBox3});
  }
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: ${theme
      .breakpoints.desktop.max}) {
    top: 120px;
    &:after {
      width: 140px;
      left: -150px;
    }
  }
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: 1200px) {
    &:after {
      left: -50px;
      top: -80px;
    }
  }

  @media (max-width: ${theme.breakpoints.tablet.max}) {
    bottom: 30px;
    right: 0;
    left: auto;
    top: auto;
    width: 130px;
    &:after {
      width: 42px;
      height: 85px;
      top: -85px;
      left: -20px;
      background-image: url(${arrowBox4Mobile});
    }
  }
`;

const DescriptionFour = styled(Description)`
  top: 500px;
  right: 0;
  width: 230px;
  &:after {
    width: 233px;
    height: 144px;
    bottom: -164px;
    left: -130px;
    background-image: url(${arrowBox4});
  }
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: ${theme
      .breakpoints.desktop.max}) {
    top: 280px;
    right: 60px;
    &:after {
      width: 180px;
      left: -80px;
    }
  }
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: 1200px) {
    &:after {
      left: 0;
    }
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    bottom: 30px;
    left: 0;
    top: auto;
    width: 130px;
    &:after {
      width: 87px;
      height: 125px;
      left: 60px;
      bottom: 35px;
      background-image: url(${arrowBox3Mobile});
    }
  }
`;

function Boxes() {
  const { t } = useTranslation();
  return (
    <StyledSection id={"boxes"}>
      <BoxesContainer>
        <StyledTitle>{t("skrzynki i regały")}</StyledTitle>
        <Image>
          <DescriptionOne>
            {t("skrzynki i regały wyprodukowany z litego drewna")}
          </DescriptionOne>
          <DescriptionTwo>{t("nowoczesny i elegancki wygląd")}</DescriptionTwo>
          <img src={box} alt={t("box")} />
          <DescriptionThree>
            {t("regał idealnie zaprojektowany pod skrzynki")}
          </DescriptionThree>
          <DescriptionFour>
            {t("swobodny kontakt z powietrzem")}
          </DescriptionFour>
        </Image>
      </BoxesContainer>
    </StyledSection>
  );
}

export default Boxes;
