import React from "react";
import styled from "styled-components";
import footer from "../assets/images/footer.svg";
import footerMobile from "../assets/images/footer_mobile.svg";
import { theme } from "../theme/config";
import { Container, Text, MainSection } from "../theme/shared";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const StyledSection = styled(MainSection)`
  background-color: ${theme.colors.green.default};
  background-image: url(${footer});
  background-position: right 300px;
  background-repeat: no-repeat;
  padding-top: 5rem;
  padding-bottom: 18.75rem;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    background-image: url(${footerMobile});
    background-position: center 450px;
    padding-top: 3.125rem;
    padding-bottom: 13.75rem;
  }
`;

const FooterContainer = styled(Container)`
  position: relative;
  color: ${theme.colors.white.default};
  display: flex;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    flex-direction: column;
  }
`;

const Slogan = styled.h2`
  font-size: 4.25rem;
  font-family: ${theme.fonts.headers};
  margin-bottom: 55px;

  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 2.5rem;
    line-height: 2.5rem;
  }
`;

const Address = styled(Text)`
  line-height: 1.563rem;
  margin-left: auto;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    margin-left: 0;
    margin-bottom: 1.5rem;
    line-height: 1.125rem;
  }
  a {
    color: ${theme.colors.orange.default};
    text-decoration: none;
  }
`;

function Footer() {
  const { t } = useTranslation();
  return (
    <StyledSection id={"contact"}>
      <FooterContainer>
        <Slogan>
          {t("zacznijmy")}
          <br />
          {t("owocną")}
          <br />
          {t("współprace")}
        </Slogan>
        <Address>
          Biuro Obsługi Klienta
          <br />
          Ul. Wodnika 50
          <br />
          80-299, Gdańsk
          <br />
          NIP: 522-322-56-55
          <br />
          <br />
          +48 575 236 669
          <br />
          biuro@owocedobiura.pl
          <br />
          godziny otwarcia: 8:00 - 16:00
          <br />
          <br />
          <Link to={`/polityka-prywatnosci`}>Polityka prywatności</Link>
        </Address>
        <Address>
          Zapytania ofertowe
          <br />
          +48 575 236 669
          <br />
          zapytania@owocedobiura.pl
        </Address>
      </FooterContainer>
    </StyledSection>
  );
}

export default Footer;
