import styled from "styled-components";
import { theme } from "./config";
import { Section } from "react-scroll-section";

export const Container = styled.div`
  max-width: ${theme.grid.main};
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0;
`;

export const MainSection = styled(Section)`
  padding: 0 2rem;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    padding: 0 2.5rem;
  }
`;

export const Title = styled.h2`
  font-size: 3.75rem;
  font-family: ${theme.fonts.headers};
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    font-size: 2.5rem;
    line-height: 2.813rem;
  }
`;

export const Header = styled.h2`
  font-size: 1.563rem;
  font-family: ${theme.fonts.headers};
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    font-size: 1.438rem;
  }
`;

export const Text = styled.p`
  font-size: 1rem;
  line-height: 1.75rem;
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    font-size: 0.75rem;
    line-height: 1.125rem;
    margin-bottom: 1.5rem;
  }
`;
