import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { theme } from "./theme/config";
import "./i18n";
import "./fonts/NewHero/NewHero-Regular.woff2";
import "./fonts/NewHero/NewHero-Regular.woff";
import "./fonts/CeraPROBold/font.woff2";
import "./fonts/CeraPROBold/font.woff";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

const GlobalStyle = createGlobalStyle`
    ${reset}
    @font-face {
    font-family: 'Hero New Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Hero New Regular'), url("./fonts/NewHero/NewHero-Regular.woff2") format('woff2'), url("./fonts/NewHero/NewHero-Regular.woff") format('woff');
    }

    @font-face {
    font-family: 'CeraPro-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('CeraPro-Bold'), url("./fonts/CeraPROBold/font.woff2") format('woff2'), url("./fonts/CeraPROBold/font.woff") format('woff');
    }

  body {
    margin: 0;
    font-size: 16px;
    font-family: ${theme.fonts.default};
  }
  * {
    box-sizing: border-box;
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyle />
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
