import React from "react";
import styled from "styled-components";
import { theme } from "../theme/config";
import { useTranslation } from "react-i18next";
import { Container, MainSection } from "../theme/shared";
import hero from "../assets/images/hero.svg";
import arrowOrange from "../assets/images/arrow-orange.svg";
import arrowHeroMobile from "../assets/images/hero_arrow_mobile.svg";

const StyledSection = styled(MainSection)`
  background: ${theme.colors.green.default};
  margin-top: -50px;
`;

const Header = styled.h1`
  color: ${theme.colors.white.default};
  font-family: ${theme.fonts.headers};
  font-size: 4.4rem;
  line-height: 110%;
  padding: 30px 0 0;
  margin-bottom: 130px;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 2.5rem;
    line-height: 2.813rem;
    margin-bottom: 3.438rem;
  }
`;

const Button = styled.a`
  background: ${theme.colors.orange.default};
  color: ${theme.colors.green.default};
  font-family: ${theme.fonts.headers};
  font-size: 1.563rem;
  line-height: 1;
  border-radius: 44px;
  padding: 1.875rem 5.625rem;
  display: inline-block;
  text-decoration: none;
  position: relative;

  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 0.938rem;
    padding: 0.938rem 2.5rem;
  }
  &:before {
    content: " ";
    background: url(${arrowOrange}) no-repeat;
    width: 8.375rem;
    height: 7.625rem;
    position: absolute;
    top: -6.375rem;
    right: -8.375rem;
    @media (max-width: ${theme.breakpoints.tablet.max}) {
      width: 6.125rem;
      height: 4.875rem;
      right: -6.5rem;
      top: -3rem;
      background: url(${arrowHeroMobile}) no-repeat;
    }
  }
`;

const HeroContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    flex-direction: column;
    align-items: start;
  }
`;

const Text = styled.div`
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: 1320px) {
    width: 50%;
  }
`;

const Image = styled.div`
  padding-left: 45px;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    padding-left: 0;
  }
  @media (min-width: ${theme.breakpoints.desktop.min}) and (max-width: 1320px) {
    width: 50%;
  }
  img {
    max-width: 100%;
  }
`;

function Hero() {
  const { t } = useTranslation();
  return (
    <StyledSection id={"home"}>
      <HeroContainer>
        <Text>
          <Header>
            {t("owocny team")}
            <br />
            {t("może wszystko")}
          </Header>
          <Button href={"/assets/pdf/Katalog_Owoce_Do_Biura_2023.pdf"}>
            {t("zobacz katalog")}
          </Button>
        </Text>
        <Image>
          <img src={hero} alt={"owocedobiura.pl"} />
        </Image>
      </HeroContainer>
    </StyledSection>
  );
}

export default Hero;
