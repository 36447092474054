import React from "react";
import Hero from "./components/hero";
import Benefits from "./components/benefits";
import HowItWorks from "./components/how_it_works";
import Calculator from "./components/calculator";
import OrderForm from "./components/order_form";
import Boxes from "./components/boxes";
import Mission from "./components/mission";
import OurBrands from "./components/our_brands";

function Home() {
  return (
    <>
      <Hero />
      <Benefits />
      <HowItWorks />
      <Calculator />
      <OrderForm />
      <Boxes />
      <Mission />
      <OurBrands />
    </>
  );
}

export default Home;
