import React from "react";
import styled from "styled-components";
import { theme } from "../theme/config";
import { useTranslation } from "react-i18next";
import { Container, Header, MainSection, Text } from "../theme/shared";
import benefitsClient from "../assets/images/benefits_client.svg";
import benefitsEmployes from "../assets/images/benefits_employes.svg";

const StyledSection = styled(MainSection)`
  background: ${theme.colors.green.default};
`;

const BenefitsContainer = styled(Container)`
  padding: 265px 0 0;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    padding: 5.625rem 0 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    flex-wrap: wrap;
  }
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;

  @media (max-width: ${theme.breakpoints.desktop.max}) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  @media (max-width: ${theme.breakpoints.mobile.max}) {
    width: 100%;
    margin-bottom: 25px;
  }
`;

const StyledHeader = styled(Header)`
  line-height: 1.75rem;
  color: ${theme.colors.white.default};
  margin-bottom: 1.25rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 0.938rem;
    line-height: 1.375rem;
  }
`;

const StyledText = styled(Text)`
  color: ${theme.colors.white.default};
  font-size: 1rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    font-size: 0.625rem;
    line-height: 0.938rem;
  }
`;

const Icon = styled.div`
  width: 215px;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    width: 190px;
    padding-right: 2rem;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    width: 140px;
    padding-right: 0;
    margin-right: 0;
    img {
      max-width: 100%;
    }
  }
`;

const IconCompany = styled(Icon)`
  img {
    margin-top: 30px;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    img {
      margin-top: 0;
      width: 97px;
    }
  }
`;

const IconPerson = styled(Icon)`
  img {
    margin-top: 25px;
    margin-left: 40px;
  }
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    img {
      margin-top: -45px;
      margin-left: 20px;
      width: 65px;
    }
  }
`;

const Item = styled.div``;

function Benefits() {
  const { t } = useTranslation();
  return (
    <StyledSection id={"benefits"}>
      <BenefitsContainer>
        <Row>
          <Column>
            <IconCompany>
              <img src={benefitsClient} alt={t("Benefits for client")} />
            </IconCompany>
            <Item>
              <StyledHeader>{t("korzyści dla firmy")}</StyledHeader>
              <StyledText>
                {t("dbałość o zdrowie i dobre samopoczucie pracowników,")}
                <br />
                {t("zwiększenie wydajności w pracy,")}
                <br />
                {t("nowoczesny wizerunek firmy,")}
                <br />
                {t("dodatkowy atut w trakcie rekrutacji")}
              </StyledText>
            </Item>
          </Column>
          <Column>
            <IconPerson>
              <img src={benefitsEmployes} alt={t("Benefits for employess")} />
            </IconPerson>
            <Item>
              <StyledHeader>{t("korzyści dla pracownika")}</StyledHeader>
              <StyledText>
                {t("zdrowa przekąska w miejscu pracy,")}
                <br />
                {t("zwiększenie odporności,")}
                <br />
                {t("dobre samopoczucie")}
              </StyledText>
            </Item>
          </Column>
        </Row>
      </BenefitsContainer>
    </StyledSection>
  );
}

export default Benefits;
