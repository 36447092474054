export const theme = {
  colors: {
    white: {
      default: "#ffffff",
    },
    black: {
      default: "#000000",
    },
    green: {
      default: "#15512C",
      light: "#00A95C",
    },
    orange: {
      default: "#FFB511",
    },
    pink: {
      default: "#F587C7",
    },
    red: {
      default: "#FE5442",
      sendgift: "#cf0f0f",
    },
    purple: {
      default: "#C9B7E8",
    },
  },
  grid: {
    main: "1500px",
  },
  breakpoints: {
    mobile: {
      min: 0,
      max: "768px",
    },
    tablet: {
      min: "769px",
      max: "1024px",
    },
    desktop: {
      min: "1024px",
      max: "1490px",
    },
    desktopXl: {
      min: "1500px",
    },
  },
  fonts: {
    default: "Hero New Regular",
    headers: "CeraPro-Bold",
  },
};
