import React, { useEffect } from "react";
import styled from "styled-components";
import { Container, Header, MainSection, Text, Title } from "./theme/shared";
import { theme } from "./theme/config";
import { useLocation } from "react-router-dom";

const StyledSection = styled(MainSection)`
  background: ${theme.colors.green.default};
`;

const PolicyContainer = styled(Container)`
  padding: 0 2rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    padding: 0;
  }
`;

const PolicyHeader = styled(Title)`
  text-align: center;
  margin-bottom: 2.5rem;
`;

const PolicySection = styled.section`
  padding-bottom: 2rem;
  color: ${theme.colors.white.default};
  b {
    font-family: ${theme.fonts.headers};
    color: ${theme.colors.orange.default};
  }
  a {
    color: ${theme.colors.orange.default};
    text-decoration: underline;
  }
  li {
    font-size: 1rem;
    line-height: 1.75rem;
    list-style: disc;
    margin-left: 1rem;
    li {
      list-style: circle;
      li {
        list-style: "-";
        padding-left: 0.75rem;
      }
    }
    @media (max-width: ${theme.breakpoints.mobile.max}) {
      font-size: 0.75rem;
      line-height: 1.125rem;
      margin-bottom: 1.5rem;
    }
  }
  ul.numeric {
    padding-left: 1rem;
    li {
      list-style: decimal;
    }
  }
  ul.alpha {
    padding-left: 1rem;
    li {
      list-style: lower-alpha;
    }
  }
`;

const PolicySubHeader = styled(Header)`
  margin-bottom: 1rem;
  text-align: center;
`;

const PolicyParagraph = styled(Text)`
  margin-bottom: 0;
`;

function Policy() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <StyledSection id={"policy"}>
        <PolicyContainer>
          <PolicySection>
            <PolicyHeader>Polityka Prywatności</PolicyHeader>
            <PolicyParagraph>
              Poniższa Polityka Prywatności określa{" "}
              <b>
                zasady zapisywania i uzyskiwania dostępu do danych na
                Urządzeniach Użytkowników
              </b>{" "}
              korzystających z Serwisu do celów świadczenia usług drogą
              elektroniczną przez Administratora oraz{" "}
              <b>
                zasady gromadzenia i przetwarzania danych osobowych
                Użytkowników,
              </b>{" "}
              które zostały podane przez nich osobiście i dobrowolnie za
              pośrednictwem narzędzi dostępnych w Serwisie.
              <br />
              <br />
              Polityka prywatności została ostatnio zaktualizowana dnia 1
              sierpnia 2024 r.
            </PolicyParagraph>
          </PolicySection>
          <PolicySection>
            <PolicySubHeader>§1 Definicje</PolicySubHeader>
            <ul>
              <li>
                <b>Serwis</b> - serwis internetowy &quot;owocedobiura.pl&quot;
                działający pod adresem https://owocedobiura.pl/
              </li>
              <li>
                <b>Serwis zewnętrzny</b> - serwisy internetowe partnerów,
                usługodawców lub usługobiorców współpracujących z
                Administratorem
              </li>
              <li>
                <b>Administrator Serwisu / Danych</b> - Administratorem Serwisu
                oraz Administratorem Danych (dalej Administrator) jest firma
                &quot;Daily Krudysz S.K.A.&quot;, prowadząca działalność pod
                adresem: ul. Modułowa 3,  05-090 Raszyn, o nadanym
                numerze identyfikacji podatkowej (NIP): 522-322-56-55, na
                potrzeby lepszego odbioru Polityki Prywatności termin
                „Administrator” został zastąpiony określeniem „My” lub
                „Usługodawca”.
              </li>
              <li>
                <b>Użytkownik</b> - osoba fizyczna, dla której Administrator
                świadczy usługi drogą elektroniczną za pośrednictwem Serwisu.
              </li>
              <li>
                <b>Urządzenie</b> - elektroniczne urządzenie wraz z
                oprogramowaniem, za pośrednictwem którego Użytkownik uzyskuje
                dostęp do Serwisu
              </li>
              <li>
                <b>Cookies (ciasteczka)</b> - dane tekstowe gromadzone w formie
                plików zamieszczanych na Urządzeniu Użytkownika
              </li>
              <li>
                <b>RODO</b> - Rozporządzenie Parlamentu Europejskiego i Rady
                (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób
                fizycznych w związku z przetwarzaniem danych osobowych i w
                sprawie swobodnego przepływu takich danych oraz uchylenia
                dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
              </li>
              <li>
                <b>Dane osobowe</b> - należy rozumieć jako wszelkie informacje
                dotyczące zidentyfikowanej lub możliwej do zidentyfikowania
                osoby fizycznej,
              </li>
              <li>
                <b>Dane</b> - są to dane pozyskiwane przy pomocą formularza od
                Użytkownika pozostawionego przez niego w trakcie wizyty w
                witrynie, w tym także dane osobowe
              </li>
              <li>
                <b>Wrażliwe dane osobowe</b> - należy rozumieć jako dane osobowe
                związane z pochodzeniem rasowym lub etnicznym osób fizycznych;
                przekonania polityczne, religijne, filozoficzne; członkostwo w
                związku zawodowym; dane dotyczące zdrowia lub preferencji
                seksualnych; akta dotyczące przestępstw i poważne problemy
                społeczne.
              </li>
              <li>
                <b>Przetwarzanie danych osobowych</b> - jest to w zasadzie każda
                operacja wykonywana na danych osobowych, niezależnie od tego,
                czy jest dokonywana w sposób zautomatyzowany czy też nie (np.
                zbieranie, utrwalanie, organizowanie, porządkowanie,
                przechowywanie, adaptowanie lub modyfikowanie, pobieranie,
                przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie,
                rozpowszechnianie lub innego rodzaju udostępnianie,
                dopasowywanie lub łączenie, ograniczanie, usuwanie lub
                niszczenie),
              </li>
              <li>
                <b>Ograniczenie przetwarzania</b> - oznacza oznaczenie
                przechowywanych danych osobowych w celu ograniczenia ich
                przyszłego przetwarzania
              </li>
              <li>
                <b>Profilowanie</b> - oznacza dowolną formę zautomatyzowanego
                przetwarzania danych osobowych, które polega na wykorzystaniu
                danych osobowych do oceny niektórych czynników osobowych osoby
                fizycznej, w szczególności do analizy lub prognozy aspektów
                dotyczących efektów pracy tej osoby fizycznej, jej sytuacji
                ekonomicznej, zdrowia, osobistych preferencji, zainteresowań,
                wiarygodności, zachowania, lokalizacji lub przemieszczania się
              </li>
              <li>
                <b>Zgoda</b> - zgoda osoby, której dane dotyczą oznacza
                dobrowolne, konkretne, świadome i jednoznaczne okazanie woli,
                którym osoba, której dane dotyczą, w formie oświadczenia lub
                wyraźnego działania potwierdzającego, przyzwala na przetwarzanie
                dotyczących jej danych osobowych
              </li>
              <li>
                <b>Naruszenie ochrony danych osobowych</b> - oznacza naruszenie
                bezpieczeństwa prowadzące do przypadkowego lub niezgodnego z
                prawem zniszczenia, utracenia, zmodyfikowania, nieuprawnionego
                ujawnienia lub nieuprawnionego dostępu do danych osobowych
                przesyłanych, przechowywanych lub w inny sposób przetwarzanych
              </li>
              <li>
                <b>Pseudonimizacja</b> - oznacza przetworzenie danych osobowych
                w taki sposób, by nie można ich było już przypisać konkretnej
                osobie, której dane dotyczą, bez użycia dodatkowych informacji,
                pod warunkiem że takie dodatkowe informacje są przechowywane
                osobno i są objęte środkami technicznymi i organizacyjnymi
                uniemożliwiającymi ich przypisanie zidentyfikowanej lub możliwej
                do zidentyfikowania osobie fizycznej
              </li>
              <li>
                <b>Anonimizacja</b> - Anonimizacja danych to nieodwracalny
                proces operacji na danych, który niszczy / nadpisuje &quot;dane
                osobowe&quot; uniemożliwiając identyfikację, lub powiązanie
                danego rekordu z konkretnym użytkownikiem lub osobą fizyczną.
              </li>
            </ul>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>§2 Inspektor Ochrony Danych</PolicySubHeader>
            <PolicyParagraph>
              Na podstawie Art. 37 RODO, Administrator nie powołał Inspektora
              Ochrony Danych. W sprawach dotyczących przetwarzania danych, w tym
              danych osobowych, należy kontaktować się bezpośrednio z
              Administratorem
            </PolicyParagraph>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>§3 Pliki cookies</PolicySubHeader>
            <PolicyParagraph>
              Stosowane przez Administratora pliki „cookies” są bezpieczne dla
              urządzeń Klienta. W szczególności tą drogą nie jest możliwe
              przedostanie się do urządzeń Klientów wirusów lub innego
              niechcianego oprogramowania lub oprogramowania złośliwego. Pliki
              te pozwalają zidentyfikować oprogramowanie wykorzystywane przez
              Klienta i dostosować Sklep Internetowy indywidualnie każdemu
              Klientowi. Pliki „cookies” zazwyczaj zawierają nazwę domeny, z
              której pochodzą, czas przechowywania ich na urządzeniu oraz
              przypisaną wartość.
            </PolicyParagraph>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>§4 Rodzaje Plików Cookies</PolicySubHeader>
            <ul>
              <li>
                <b>Cookies wewnętrzne</b> - pliki zamieszczane i odczytywane z
                Urządzenia Użytkownika przez system teleinformatyczny Serwisu
              </li>
              <li>
                <b>Cookies zewnętrzne</b> - pliki zamieszczane i odczytywane z
                Urządzenia Użytkownika przez systemy teleinformatyczne Serwisów
                zewnętrznych. Skrypty Serwisów zewnętrznych, które mogą
                umieszczać pliki Cookies na Urządzeniach Użytkownika zostały
                świadomie umieszczone w Serwisie poprzez skrypty i usługi
                udostępnione i zainstalowane w Serwisie
              </li>
              <li>
                <b>Cookies sesyjne</b> - pliki zamieszczane i odczytywane z
                Urządzenia Użytkownika przez Serwis podczas jednej sesji danego
                Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia
                Użytkownika.
              </li>
              <li>
                <b>Cookies trwałe</b> - pliki zamieszczane i odczytywane z
                Urządzenia Użytkownika przez Serwis do momentu ich ręcznego
                usunięcia. Pliki nie są usuwane automatycznie po zakończeniu
                sesji Urządzenia chyba że konfiguracja Urządzenia Użytkownika
                jest ustawiona na tryb usuwanie plików Cookie po zakończeniu
                sesji Urządzenia.
              </li>
            </ul>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>
              §5 Bezpieczeństwo składowania danych
            </PolicySubHeader>
            <ul>
              <li>
                <b>Mechanizmy składowania i odczytu plików Cookie</b> -
                Mechanizmy składowania, odczytu i wymiany danych pomiędzy
                Plikami Cookies zapisywanymi na Urządzeniu Użytkownika a
                Serwisem są realizowane poprzez wbudowane mechanizmy
                przeglądarek internetowych i nie pozwalają na pobieranie innych
                danych z Urządzenia Użytkownika lub danych innych witryn
                internetowych, które odwiedzał Użytkownik, w tym danych
                osobowych ani informacji poufnych. Przeniesienie na Urządzenie
                Użytkownika wirusów, koni trojańskich oraz innych robaków jest
                także praktycznie niemożliwe. Istnieje również możliwość
                kontroli nad plikami cookies bezpośrednio z poziomu strony
                internetowej. W Serwisie zaimplementowany został specjalny
                mechanizm do zarządzania plikami cookies, który pozwala blokować
                wybrane pliki cookies przez Użytkownika. Należy pamiętać, że
                wyłączenie lub ograniczenie obsługi plików cookies może
                uniemożliwiać używanie części funkcji dostępnych na stronie
                internetowej oraz powodować trudności w korzystaniu z Serwisu. W
                każdej chwili użytkownik może zmienić preferencje dotyczące
                plików cookie, klikając przycisk Cookie Ustawienia . Umożliwi to
                ponowne odwiedzenie banera zgody na pliki cookie i zmianę
                preferencji lub natychmiastowe wycofanie zgody. Szczegółowe
                informacje dotyczące kategorii cookies, możliwości blokowania
                znajdują się w banerze Informacja o plikach cookies, który
                pojawia się po pierwszym uruchomieniu Serwisu.
              </li>
              <li>
                <b>Cookie wewnętrzne</b> - zastosowane przez Administratora
                pliki Cookie są bezpieczne dla Urządzeń Użytkowników i nie
                zawierają skryptów, treści lub informacji mogących zagrażać
                bezpieczeństwu danych osobowych lub bezpieczeństwu Urządzenia z
                którego korzysta Użytkownik.
              </li>
              <li>
                <b>Cookie zewnętrzne</b> - Administrator dokonuje wszelkich
                możliwych działań w celu weryfikacji i doboru partnerów serwisu
                w kontekście bezpieczeństwa Użytkowników. Administrator do
                współpracy dobiera znanych, dużych partnerów o globalnym
                zaufaniu społecznym. Nie posiada on jednak pełnej kontroli nad
                zawartością plików Cookie pochodzących od zewnętrznych
                partnerów. Za bezpieczeństwo plików Cookie, ich zawartość oraz
                zgodne z licencją wykorzystanie przez zainstalowane w serwisie
                Skrypty, pochodzących z Serwisów zewnętrznych, Administrator nie
                ponosi odpowiedzialności na tyle na ile pozwala na to prawo.
                Lista partnerów zamieszczona jest w dalszej części Polityki
                Prywatności.
              </li>
              <li>
                <b>Kontrola plików Cookie</b>
                <ul>
                  <li>
                    Użytkownik może w dowolnym momencie, samodzielnie zmienić
                    ustawienia dotyczące zapisywania, usuwania oraz dostępu do
                    danych zapisanych plików Cookies przez każdą witrynę
                    internetową
                  </li>
                  <li>
                    Informacje o sposobie wyłączenia plików Cookie w
                    najpopularniejszych przeglądarkach komputerowych dostępne są
                    na stronie:{" "}
                    <a
                      href="https://nety.pl/jak-wylaczyc-pliki-cookie/"
                      target={"_blank"}
                      rel="nofollow noreferrer"
                    >
                      jak wyłączyć cookie
                    </a>{" "}
                    lub u jednego ze wskazanych dostawców:
                    <ul>
                      <li>
                        <a
                          href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=pl"
                          target={"_blank"}
                          rel="nofollow noreferrer"
                        >
                          Zarządzanie plikami cookies w przeglądarce
                        </a>{" "}
                        Chrome
                      </li>
                      <li>
                        <a
                          href="https://help.opera.com/pl/latest/web-preferences/"
                          target={"_blank"}
                          rel="nofollow noreferrer"
                        >
                          Zarządzanie plikami cookies w przeglądarce
                        </a>{" "}
                        Opera
                      </li>
                      <li>
                        <a
                          href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek"
                          target={"_blank"}
                          rel="nofollow noreferrer"
                        >
                          Zarządzanie plikami cookies w przeglądarce
                        </a>{" "}
                        FireFox
                      </li>
                      <li>
                        <a
                          href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies"
                          target={"_blank"}
                          rel="nofollow noreferrer"
                        >
                          Zarządzanie plikami cookies w przeglądarce
                        </a>{" "}
                        Edge
                      </li>
                      <li>
                        <a
                          href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"
                          target={"_blank"}
                          rel="nofollow noreferrer"
                        >
                          Zarządzanie plikami cookies w przeglądarce
                        </a>{" "}
                        Safari
                      </li>
                      <li>
                        <a
                          href="https://windows.microsoft.com/pl-pl/internet-explorer/delete-manage-cookies#ie=ie-11"
                          target={"_blank"}
                          rel="nofollow noreferrer"
                        >
                          Zarządzanie plikami cookies w przeglądarce
                        </a>{" "}
                        Internet Explorer 11
                      </li>
                    </ul>
                  </li>
                  <li>
                    o Użytkownik może w dowolnym momencie usunąć wszelkie
                    zapisane do tej pory pliki Cookie korzystając z narzędzi
                    Urządzenia Użytkownika, za pośrednictwem którego Użytkownik
                    korzysta z usług Serwisu.
                  </li>
                </ul>
              </li>
              <li>
                <b>Zagrożenia po stronie Użytkownika</b> - Administrator stosuje
                wszelkie możliwe środki techniczne w celu zapewnienia
                bezpieczeństwa danych umieszczanych w plikach Cookie. Należy
                jednak zwrócić uwagę, że zapewnienie bezpieczeństwa tych danych
                zależy od obu stron w tym działalności Użytkownika.
                Administrator nie bierze odpowiedzialności za przechwycenie tych
                danych, podszycie się pod sesję Użytkownika lub ich usunięcie,
                na skutek świadomej lub nieświadomej działalność Użytkownika,
                wirusów, koni trojańskich i innego oprogramowania szpiegującego,
                którymi może jest lub było zainfekowane Urządzenie Użytkownika.
                Użytkownicy w celu zabezpieczenia się przed tymi zagrożeniami
                powinni stosować się do{" "}
                <a
                  href="https://nety.pl/cyberbezpieczenstwo/"
                  target={"_blank"}
                  rel="nofollow noreferrer"
                >
                  zaleceń bezpiecznego korzystania z internetu
                </a>
                .
              </li>
              <li>
                <b>Przechowywanie danych osobowych</b> - Administrator zapewnia,
                że dokonuje wszelkich starań, by przetwarzane dane osobowe
                wprowadzone dobrowolnie przez Użytkowników były bezpieczne,
                dostęp do nich był ograniczony i realizowany zgodnie z ich
                przeznaczeniem i celami przetwarzania. Administrator zapewnia
                także, że dokonuje wszelkich starań w celu zabezpieczenia
                posiadanych danych przed ich utratą, poprzez stosowanie
                odpowiednich zabezpieczeń fizycznych jak i organizacyjnych.
              </li>
            </ul>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>
              §6 Cele do których wykorzystywane są pliki Cookie
            </PolicySubHeader>
            <PolicyParagraph>
              Administrator wykorzystuje pliki „cookies” w następujących celach:
            </PolicyParagraph>
            <ul className={"alpha"}>
              <li>konfiguracji strony internetowego;</li>
              <li>
                tworzenia statystyk, które pomagają zrozumieć, w jaki sposób
                Klienci o korzystają ze stron internetowych, co umożliwia
                ulepszanie ich struktury i zawartości za pośrednictwem narzędzi
                analitycznych Google Analytics, których administratorem jest
                Google Inc z siedzibą w USA, Polityka ochrony prywatności Google
                jest dostępna pod następującymi linkami:{" "}
                <a
                  href="https://www.google.com/intl/pl/policies/privacy/"
                  target={"_blank"}
                  rel="nofollow noreferrer"
                >
                  https://www.google.com/intl/pl/policies/privacy/;
                </a>
                <a
                  href="https://www.google.com/intl/pl/policies/privacy/partners/"
                  target={"_blank"}
                  rel="nofollow noreferrer"
                >
                  https://www.google.com/intl/pl/policies/privacy/partners/;
                </a>
              </li>
            </ul>
            <ul className={"numeric"}>
              <li>
                Pliki „cookies” mogą być wykorzystane przez sieci reklamowe, w
                szczególności sieć Google, do wyświetlenia reklam dopasowanych
                do sposobu, w jaki Klient korzysta ze strony internetowej. W tym
                celu mogą zostać zachowane informacje o ścieżce nawigacji
                Klienta lub czasie pozostawania na danej stronie.
              </li>
              <li>
                W zakresie informacji o preferencjach Klienta gromadzonych przez
                sieć reklamową Google Klient może przeglądać i edytować
                informacje wynikające z plików „cookies” przy pomocy narzędzia:
                https://www.google.com/ads/preferences/;
              </li>
              <li>
                Klient może samodzielnie i w każdym czasie zmienić ustawienia
                dotyczące plików „cookies”, określając warunki ich
                przechowywania i uzyskiwania dostępu przez pliki „cookies” do
                urządzenia Klienta. Zmiany ustawień, o których mowa w zdaniu
                poprzednim, Klient może dokonać za pomocą ustawień przeglądarki
                internetowej lub za pomocą konfiguracji usługi. Ustawienia te
                mogą zostać zmienione w szczególności w taki sposób, aby
                blokować automatyczną obsługę plików „cookies” w ustawieniach
                przeglądarki internetowej bądź informować o ich każdorazowym
                zamieszczeniu na urządzeniu Klienta. Szczegółowe informacje o
                możliwości i sposobach obsługi plików „cookies” dostępne sąw
                ustawieniach oprogramowania (przeglądarki internetowej);
              </li>
              <li>Usprawnienie i ułatwienie dostępu do Serwisu;</li>
            </ul>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>
              §7 Cele przetwarzania danych osobowych
            </PolicySubHeader>
            <PolicyParagraph>
              Dane osobowe dobrowolnie podane przez Użytkowników są przetwarzane
              w jednym z następujących celów:
            </PolicyParagraph>
            <ul>
              <li>
                Realizacji usług elektronicznych:
                <ul>
                  <li>
                    Usługi Newslettera (w tym przesyłania za zgodą treści
                    reklamowych)
                  </li>
                  <li>
                    Usługi udostępniania informacji o treści umieszczonych w
                    Serwisie w serwisach społecznościowych lub innych witrynach.
                  </li>
                </ul>
              </li>
              <li>
                Komunikacji Administratora z Użytkownikami w sprawach związanych
                z Serwisem oraz ochrony danych
              </li>
              <li>
                Dane o Użytkownikach gromadzone anonimowo i automatycznie są
                przetwarzane w jednym z następujących celów:
                <ul>
                  <li>Prowadzenie statystyk</li>
                  <li>Remarketing</li>
                  <li>
                    Zapewnienia prawnie uzasadnionego interesu Administratora
                  </li>
                </ul>
              </li>
            </ul>

            <PolicyParagraph>
              <b>1. Zakres i cel zbieranych danych</b>
            </PolicyParagraph>
            <PolicyParagraph>Dane osobowe:</PolicyParagraph>
            <ul className={"numeric"}>
              <li>
                Przetwarzamy wszystkie dane, w tym również dane osobowe, jakie
                pozostawisz w trakcie wizyty w naszej Witrynie.
              </li>
              <li>
                W szczególności przetwarzamy następujące kategorie danych
                osobowych, przekazane przez Ciebie za pośrednictwem formularza:
                <ul className={"alpha"}>
                  <li>imię i nazwisko,</li>
                  <li>adres/miejsce zamieszkania,</li>
                  <li>adres e – mail,</li>
                  <li>numer telefonu.</li>
                </ul>
              </li>
              <li>
                Oświadczamy i zapewniamy, że Twoje dane osobowe są przetwarzane
                zgodnie z powszechnymi przepisami prawa regulującymi ochronę
                danych osobowych, w tym z postanowieniami RODO, a także zgodnie
                z wdrożoną przez nas Polityką Prywatności.
              </li>
              <li>
                Twoje dane osobowe przetwarzane są w następujących celach:
                <ul className={"alpha"}>
                  <li>
                    udzielania odpowiedzi na zapytanie (kierowane do nas za
                    pomocą formularza w Witrynie) w przedmiocie interesujących
                    Ciebie, a oferowanych przez nas produktach i świadczonych
                    usługach, w zamiarze zawarcia z nami umowy, na podstawie
                    art. 6 ust 1 lit. b RODO, zgodnie z którym przetwarzanie
                    danych jest dozwolone, jeżeli jest niezbędne do wykonania
                    umowy, której stroną jest osoba, której dane dotyczą, lub do
                    podjęcia działań na żądanie osoby, której dane dotyczą,
                    przed zawarciem umowy;
                  </li>
                  <li>
                    zawarcia i wykonania umowy, na podstawie art. 6 ust 1 lit. b
                    RODO, zgodnie z którym przetwarzanie danych jest dozwolone,
                    jeżeli jest niezbędne do wykonania umowy, której stroną jest
                    osoba, której dane dotyczą, lub do podjęcia działań na
                    żądanie osoby, której dane dotyczą, przed zawarciem umowy;
                  </li>
                  <li>
                    obrony przed roszczeniami i ewentualnego dochodzenia
                    powstałych roszczeń, na podstawie art. 6 ust 1 lit. f RODO,
                    zgodnie z którym przetwarzanie danych jest dozwolone, jeżeli
                    przetwarzanie jest niezbędne do celów wynikających z prawnie
                    uzasadnionych interesów realizowanych przez Administratora
                    lub przez stronę trzecią;
                  </li>
                  <li>
                    marketingowych i promocyjnych (otrzymywania przez Ciebie od
                    nas informacji handlowych oraz newslettera związanych z
                    przedmiotem naszej działalności), na podstawie art. 6 ust 1
                    lit. a RODO, zgodnie z którym przetwarzanie danych jest
                    dozwolone, jeżeli osoba, której dane dotyczą wyraziła zgodę
                    na przetwarzanie swoich danych osobowych w jednym lub
                    większej liczbie określonych celów.
                  </li>
                </ul>
              </li>
              <li>
                Nigdy nie poprosimy Cię o podanie wrażliwych danych osobowych
                podczas korzystania ze Strony internetowej lub Usług.
              </li>
              <li>
                Nie zbieramy świadomie danych osobowych użytkowników, którzy nie
                ukończyli 13. roku życia, bez wyraźnej zgody rodzica lub
                opiekuna. Jeśli dowiemy się, że zebraliśmy dane osobowe
                użytkownika, który nie ukończył 13. roku życia, bez zgody
                rodzica lub opiekuna, usuniemy te dane bez zbędnej zwłoki. Nie
                sprzedajemy w sposób świadomy danych osobowych użytkowników,
                którzy nie ukończyli 16. roku życia.
              </li>
            </ul>

            <PolicyParagraph>
              <b>2. Prawa i obowiązki osoby, której Dane Osobowe dotyczą</b>
            </PolicyParagraph>
            <ul className={"numeric"}>
              <li>
                Każda osoba, której dane dotyczą (jeżeli jesteśmy ich
                Administratorem) ma prawo:
                <ul className={"alpha"}>
                  <li>dostępu do swoich danych osobowych,</li>
                  <li>ich sprostowania,</li>
                  <li>
                    wycofania zgody na ich przetwarzanie (w przypadku
                    przetwarzania danych osobowych na podstawie zgody),
                  </li>
                  <li>
                    ich usunięcia (w tym m. in. na skutek cofnięcia zgody bądź
                    wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie
                    danych osobowych),
                  </li>
                  <li>ograniczenia przetwarzania,</li>
                  <li>przenoszenia danych osobowych,</li>
                  <li>
                    wniesienia sprzeciwu wobec przetwarzania danych osobowych,
                  </li>
                  <li>
                    wniesienia skargi do organu nadzorczego, tj. Prezesa Urzędu
                    Ochrony Danych Osobowych.
                  </li>
                </ul>
              </li>
              <li>
                Zasady związane z realizacją wskazanych w ust. 6 uprawnień
                zostały opisane szczegółowo w art. 15 – 21 RODO.
              </li>
              <li>
                Podanie danych osobowych w celu zawarcia i realizacji
                umowy/zamówienia przez Administratora jest niezbędne do
                wykonania objętego nią świadczenia. Brak podania danych
                osobowych uniemożliwi zawarcie umowy/ realizację zamówienia. W
                pozostałym zakresie, tj. w celach marketingowych i promocyjnych
                podanie przez Ciebie danych osobowych jest dobrowolne. Wyłączną
                konsekwencją braku wyrażenia zgody będzie fakt, że nie będziesz
                otrzymywać od nas informacji handlowych oraz newslettera
                związanych z przedmiotem naszej działalności.
              </li>
            </ul>

            <PolicyParagraph>
              <b>3. Ochrona Danych Osobowych</b>
            </PolicyParagraph>
            <ul className={"numeric"}>
              <li>
                Szanujemy Twoje prawo do prywatności i dbamy o bezpieczeństwo
                danych, dlatego też dokładamy wszelkich starań, ażeby chronić
                przekazane nam dane osobowe przed nieuprawnionym dostępem do
                nich osób nieupoważnionych i w tym zakresie stosujemy
                odpowiednie organizacyjne oraz techniczne środki bezpieczeństwa.
              </li>
              <li>
                Wiedz, że Twoje dane osobowe są przechowywane na zabezpieczonych
                serwerach.
              </li>
              <li>
                W celu zapewnienia bezpieczeństwa danych używany jest m. in.
                bezpieczny protokół szyfrowania komunikacji korzystania z
                Witryny.
              </li>
              <li>
                Wdrożyliśmy pseudonimizację, szyfrowanie danych oraz mamy
                wprowadzoną kontrolę dostępu dzięki czemu minimalizujemy skutki
                ewentualnego naruszenia bezpieczeństwa danych.
              </li>
              <li>
                Dane osobowe podawane na Witrynie są traktowane jako poufne i
                nie są widoczne dla osób postronnych z wyjątkiem nas (czyli
                Administratora) oraz uprawnionych przez nas podmiotów.
              </li>
              <li>
                Nie udostępniamy Twoich danych osobowych innym podmiotom,
                aniżeli upoważnionym na podstawie właściwych przepisów prawa.
              </li>
              <li>
                Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas
                albo podmioty przetwarzające, z którymi ściśle współpracujemy,
                m. in. zapewniające nam obsługę teleinformatyczną lub
                przechowywanie danych na serwerze, przy czym takie podmioty
                przetwarzają dane osobowe na podstawie umowy z Administratorem i
                tylko zgodnie z poleceniami Administratora.
              </li>
              <li>
                Wszystkie podmioty, którym powierzamy przetwarzanie danych
                osobowych, gwarantują stosowanie odpowiednich środków ochrony i
                bezpieczeństwa danych osobowych, a wymaganych przez obowiązujące
                przepisy prawa.
              </li>
              <li>
                Oświadczamy, że:
                <ul className={"alpha"}>
                  <li>
                    nie przekazujemy Twoich danych osobowych do państwa trzecich
                    lub organizacji międzynarodowej,
                  </li>
                  <li>
                    zapewniamy, by osoby upoważnione do przetwarzania danych
                    osobowych zobowiązały się do zachowania tajemnicy,
                  </li>
                  <li>
                    podejmujemy wszelkie środki wymagane na mocy art. 32 RODO,
                  </li>
                  <li>
                    przestrzegamy warunków korzystania z usług innego podmiotu
                    przetwarzającego, o których mowa w art. 28 ust. 1-4, 9 RODO,
                  </li>
                  <li>
                    Twoje dane osobowe nie podlegają zautomatyzowanemu
                    podejmowaniu decyzji, w tym profilowaniu.
                  </li>
                </ul>
              </li>
              <li>
                Dane są przechowywane przez okres niezbędny do wykonania,
                rozwiązania lub wygaśnięcia umowy, rozliczenia usługi, przez
                okres, po którym przedawnią się ewentualne roszczenia, a jeżeli
                ma to zastosowanie przez okres niezbędny na potrzeby rozliczenia
                i zamknięcia projektu oraz do czasu zakończenia archiwizowania
                dokumentacji.
              </li>
            </ul>

            <PolicyParagraph>
              <b>4. Portale społecznościowe</b>
            </PolicyParagraph>
            <PolicyParagraph>
              Administrator przetwarza Dane Osobowe osób odwiedzających profile
              Administratora prowadzone w mediach społecznościowych (Facebook,
              YouTube, Instagram, Twitter, t). Dane te są przetwarzane wyłącznie
              w związku z prowadzeniem profilu, w tym w celu informowania o
              aktywności Administratora i promowania różnego rodzaju wydarzeń,
              usług oraz produktów.
            </PolicyParagraph>

            <PolicyParagraph>
              <b>5. Obowiązki Usługodawcy</b>
            </PolicyParagraph>
            <ul className={"numeric"}>
              <li>
                Usługodawca zastrzega sobie prawo ujawnienia wybranych Danych
                Osobowych właściwym organom władzy bądź osobom trzecim, które
                zgłoszą żądanie udzielenia takich informacji, w oparciu o
                odpowiednią podstawę prawną, zgodnie z przepisami obowiązującego
                prawa.
              </li>
              <li>
                Usługodawca oświadcza, że powierza przetwarzanie Danych
                Osobowych, na podstawie pisemnej umowy zawartej zgodnie z
                obowiązującymi przepisami podmiotom świadczącym na rzecz
                Usługodawcy usługi hostingu, administracji, utrzymywania oraz
                zarządzania Serwisem, jak również usługi w zakresie
                optymalizacji przekazu oraz zarządzania kampaniami promocyjnymi.
              </li>
              <li>
                Usługodawca oświadcza, że powierza przetwarzanie Danych
                Osobowych Klienta podmiotom świadczącym na rzecz Usługodawcy lub
                bezpośrednio Klienta usługi w zakresie niezbędnym do korzystania
                z Serwisu, w tym realizacji umów, w szczególności
                Zamówienia/Rezerwacji, rozpatrzenia reklamacji oraz korzystania
                z usług finansowych dostępnych stronie internetowej, na
                podstawie pisemnej umowy zawartej zgodnie z obowiązującymi
                przepisami. Dane Osobowe Klientów mogą zostać powierzone w
                szczególności dostawcom Produktów, Bankom, Ubezpieczycielom,
                instytucjom finansowym i ubezpieczeniowym.
              </li>
              <li>
                Dane Osobowe mogą być przetwarzane na terenie Polski, Państwa
                Członkowskiego Unii Europejskiej (UE), na terenie sygnatariusza
                Porozumienia Europejskiego Obszaru Gospodarczego (EOG). Dane
                Osobowe mogą być przetwarzane także poza EOG, poprzez ich
                przekazanie przez Usługodawcę lub Przetwarzającego, ale tylko
                wtedy, gdy jest to konieczne dla realizacji celów zawartych w
                Umowie, i z zapewnieniem odpowiedniego stopnia ochrony, przede
                wszystkim poprzez:
                <ul className={"alpha"}>
                  <li>
                    współpracę z podmiotami przetwarzającymi Dane Osobowe w
                    państwach, w odniesieniu do których została wydana stosowna
                    decyzja Komisji Europejskiej dotycząca stwierdzenia
                    zapewnienia odpowiedniego stopnia ochrony Danych Osobowych;
                  </li>
                  <li>
                    stosowanie standardowych klauzul umownych wydanych przez
                    Komisję Europejską;
                  </li>
                  <li>
                    stosowanie wiążących reguł korporacyjnych, zatwierdzonych
                    przez właściwy organ nadzorczy.
                  </li>
                </ul>
              </li>
            </ul>
            <PolicyParagraph>
              O zamiarze przekazania Danych Osobowych Usługodawca informuje na
              etapie ich zbierania.
            </PolicyParagraph>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>
              §8 Pliki Cookies Serwisów zewnętrznych
            </PolicySubHeader>
            <PolicyParagraph>
              Administrator w Serwisie wykorzystuje skrypty javascript i
              komponenty webowe partnerów, którzy mogą umieszczać własne pliki
              cookies na Urządzeniu Użytkownika. Pamiętaj, że w ustawieniach
              swojej przeglądarki możesz sam decydować o dozwolonych plikach
              cookies jakie mogą być używane przez poszczególne witryny
              internetowe. Poniżej znajduje się lista partnerów lub ich usług
              zaimplementowanych w Serwisie, mogących umieszczać pliki cookies:
            </PolicyParagraph>
            <ul>
              <li>
                <b>Usługi multimedialne:</b>
                <ul>
                  <li>
                    <a
                      href="https://www.youtube.com/t/terms"
                      target={"_blank"}
                      rel="nofollow noreferrer"
                    >
                      YouTube
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <b>Usługi społecznościowe / łączone:</b>
                <br />
                (Rejestracja, Logowanie, udostępnianie treści, komunikacja,
                itp.)
                <ul>
                  <li>
                    <a
                      href="https://www.facebook.com/legal/terms"
                      target={"_blank"}
                      rel="nofollow noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/legal/user-agreement"
                      target={"_blank"}
                      rel="nofollow noreferrer"
                    >
                      LinkedIn
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <b>Usługi udostępniania treści:</b>
                <ul>
                  <li>
                    <a
                      href="https://help.instagram.com/519522125107875"
                      target={"_blank"}
                      rel="nofollow noreferrer"
                    >
                      Instagram
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <b>Prowadzenie statystyk:</b>
                <ul>
                  <li>
                    <a
                      href="https://policies.google.com/privacy?hl=pl"
                      target={"_blank"}
                      rel="nofollow noreferrer"
                    >
                      Google Analytics
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <b>Usługi inne:</b>
                <ul>
                  <li>
                    <a
                      href="https://www.google.com/intl/en_be/help/terms_maps/"
                      target={"_blank"}
                      rel="nofollow noreferrer"
                    >
                      Mapy Google
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
            <PolicyParagraph>
              Usługi świadczone przez podmioty trzecie są poza kontrolą
              Administratora. Podmioty te mogą w każdej chwili zmienić swoje
              warunki świadczenia usług, polityki prywatności, cel przetwarzania
              danych oraz sposów wykorzystywania plików cookie.
            </PolicyParagraph>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>§9 Rodzaje gromadzonych danych</PolicySubHeader>
            <PolicyParagraph>
              Serwis gromadzi dane o Użytkownikach. Cześć danych jest gromadzona
              automatycznie i anonimowo, a część danych to dane osobowe podane
              dobrowolnie przez Użytkowników w trakcie zapisywania się do
              poszczególnych usług oferowanych przez Serwis.
            </PolicyParagraph>
            <PolicyParagraph>
              <b>Anonimowe dane gromadzone automatycznie:</b>
            </PolicyParagraph>
            <ul>
              <li>Adres IP</li>
              <li>Typ przeglądarki</li>
              <li>Rozdzielczość ekranu</li>
              <li>Przybliżona lokalizacja</li>
              <li>Otwierane podstrony serwisu</li>
              <li>Czas spędzony na odpowiedniej podstronie serwisu</li>
              <li>Rodzaj systemu operacyjnego</li>
              <li>Adres poprzedniej podstrony</li>
              <li>Adres strony odsyłającej</li>
              <li>Język przeglądarki</li>
              <li>Prędkość łącza internetowego</li>
              <li>Dostawca usług internetowych</li>
              <li>Dane demograficzne (wiek, płeć)</li>
            </ul>
            <PolicyParagraph>
              <b>Dane gromadzone podczas rejestracji:</b>
            </PolicyParagraph>
            <ul>
              <li>Imię / nazwisko / pseudonim</li>
              <li>Adres e-mail</li>
              <li>Numer telefonu</li>
              <li>Adres IP (zbierane automatycznie)</li>
            </ul>
            <PolicyParagraph>
              <b>Dane gromadzone podczas zapisu do usługi Newsletter</b>
            </PolicyParagraph>
            <ul>
              <li>Imię / nazwisko / pseudonim</li>
              <li>Adres e-mail</li>
              <li>Adres IP (zbierane automatycznie)</li>
            </ul>
            <PolicyParagraph>
              <b>Dane gromadzone podczas dodawania komentarza</b>
            </PolicyParagraph>
            <ul>
              <li>Imię i nazwisko / pseudonim</li>
              <li>Adres e-mail</li>
              <li>Adres www</li>
              <li>Adres IP (zbierane automatycznie)</li>
            </ul>
            <PolicyParagraph>
              Część danych (bez danych identyfikujących) może być przechowywana
              w plikach cookies. Cześć danych (bez danych identyfikujących) może
              być przekazywana do dostawcy usług statystycznych.
            </PolicyParagraph>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>
              §10 Dostęp do danych osobowych przez podmioty trzecie
            </PolicySubHeader>
            <PolicyParagraph>
              Co do zasady jedynym odbiorcą danych osobowych podawanych przez
              Użytkowników jest Administrator. Dane gromadzone w ramach
              świadczonych usług nie są przekazywane ani odsprzedawane podmiotom
              trzecim.
            </PolicyParagraph>
            <PolicyParagraph>
              Dostęp do danych (najczęściej na podstawie Umowy powierzenia
              przetwarzania danych) mogą posiadać podmioty, odpowiedzialne za
              utrzymania infrastruktury i usług niezbędnych do prowadzenia
              serwisu tj.:
            </PolicyParagraph>
            <ul>
              <li>
                Firmy hostingowe, świadczące usługi hostingu lub usług
                pokrewnych dla Administratora
              </li>
            </ul>
            <PolicyParagraph>
              <b>
                Powierzenie przetwarzania danych osobowych - Usługi Hostingu,
                VPS lub Serwerów Dedykowanych
              </b>
            </PolicyParagraph>
            <PolicyParagraph>
              Administrator w celu prowadzenia serwisu korzysta z usług
              zewnętrznego dostawcy hostingu, VPS lub Serwerów Dedykowanych -
              <b>
                nazwa.pl sp. z o.o., ADMIN.NET.PL Tomasz Rzepka Arkadiusz Nowara
                S.C.{" "}
              </b>
              Wszelkie dane gromadzone i przetwarzane w serwisie są
              przechowywane i przetwarzane w infrastrukturze usługodawcy
              zlokalizowanej w Polsce. Istnieje możliwość dostępu do danych
              wskutek prac serwisowych realizowanych przez personel usługodawcy.
              Dostęp do tych danych reguluje umowa zawarta pomiędzy
              Administratorem a Usługodawcą.
            </PolicyParagraph>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>
              §11 Sposób przetwarzania danych osobowych
            </PolicySubHeader>
            <PolicyParagraph>
              <b>Dane osobowe podane dobrowolnie przez Użytkowników:</b>
            </PolicyParagraph>
            <ul>
              <li>
                Dane osobowe nie będą przekazywane poza Unię Europejską, chyba
                że zostały opublikowane na skutek indywidualnego działania
                Użytkownika (np. wprowadzenie komentarza lub wpisu), co sprawi,
                że dane będą dostępne dla każdej osoby odwiedzającej serwis.
              </li>
              <li>
                Dane osobowe nie będą wykorzystywane do zautomatyzowanego
                podejmowania decyzji (profilowania).
              </li>
              <li>Dane osobowe nie będą odsprzedawane podmiotom trzecim.</li>
            </ul>
            <PolicyParagraph>
              <b>
                Dane anonimowe (bez danych osobowych) gromadzone automatycznie:
              </b>
            </PolicyParagraph>
            <ul>
              <li>
                Dane anonimiwe (bez danych osobowych) będą przekazywane poza
                Unię Europejską.
              </li>
              <li>
                Dane anonimiwe (bez danych osobowych) nie będą wykorzystywane do
                zautomatyzowanego podejmowania decyzji (profilowania).
              </li>
              <li>
                Dane anonimiwe (bez danych osobowych) nie będą odsprzedawane
                podmiotom trzecim.
              </li>
            </ul>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>
              §12 Podstawy prawne przetwarzania danych osobowych
            </PolicySubHeader>
            <PolicyParagraph>
              Serwis gromadzi i przetwarza dane Użytkowników na podstawie:
            </PolicyParagraph>
            <ul>
              <li>
                • Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z
                dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
                związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
                (ogólne rozporządzenie o ochronie danych)
                <ul>
                  <li>
                    art. 6 ust. 1 lit. a<br />
                    osoba, której dane dotyczą wyraziła zgodę na przetwarzanie
                    swoich danych osobowych w jednym lub większej liczbie
                    określonych celów
                  </li>
                  <li>
                    art. 6 ust. 1 lit. b<br />
                    przetwarzanie jest niezbędne do wykonania umowy, której
                    stroną jest osoba, której dane dotyczą, lub do podjęcia
                    działań na żądanie osoby, której dane dotyczą, przed
                    zawarciem umowy
                  </li>
                  <li>
                    art. 6 ust. 1 lit. f<br />
                    przetwarzanie jest niezbędne do celów wynikających z prawnie
                    uzasadnionych interesów realizowanych przez administratora
                    lub przez stronę trzecią
                  </li>
                </ul>
              </li>

              <li>
                Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz.U.
                2018 poz. 1000)
              </li>
              <li>
                Ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U.
                2004 nr 171 poz. 1800)
              </li>
              <li>
                Ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach
                pokrewnych (Dz. U. 1994 Nr 24 poz. 83)
              </li>
            </ul>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>
              §13 Okres przetwarzania danych osobowych
            </PolicySubHeader>
            <ul className={"numeric"}>
              <li>
                Twoje dane osobowe przechowujemy przez okresy wskazane i
                wymagane przepisami prawa lub gdy jest to konieczne do
                prawidłowego funkcjonowania Administratora i zachowania przez
                nas zasady rozliczalności, tzn. abyśmy mogli wykazać
                przestrzeganie przepisów o ochronie Twoich danych osobowych. W
                związku z tym zastrzegamy sobie prawo do przetwarzania Twoich
                danych osobowych:
                <ul className={"alpha"}>
                  <li>
                    przez okres realizacji umowy (świadczenia usług) oraz w
                    uzasadnionych wypadkach, także po zrealizowaniu umowy
                    (zakończeniu ich świadczenia), o ile jest to dozwolone lub
                    wymagane przez przepisy obowiązującego prawa (np.
                    przetwarzanie w celach rozliczeniowych, w celu dochodzenia
                    roszczeń lub upływu terminu przedawnienia). W takim
                    przypadku dane osobowe przetwarzane będą jedynie przez czas
                    niezbędny do realizacji odpowiednich celów;
                  </li>
                  <li>
                    do momentu wycofania przez Ciebie zgody na takie
                    przetwarzanie.
                  </li>
                </ul>
              </li>
              <li>
                Będzie przechowywać Twoje dane osobowe tylko tak długo, jak
                długo będziesz posiadać profil w naszym cyfrowym ekosystemie, o
                ile dalsze przechowywanie Twoich danych osobowych jest konieczne
                do wykonania umowy, której jesteś stroną, w celu spełnienia
                obowiązku prawnego którym podlegamy lub w celu realizacji
                uzasadnionych interesów realizowanych przez nas.
              </li>
              <li>
                Powinieneś mieć na uwadze, że cofnięcie zgody na przetwarzanie
                danych osobowych nie wpływa na zgodność z prawem przetwarzania,
                którego dokonano zgodnie z prawem, przed jej wycofaniem. Ponadto
                wycofanie zgody może nastąpić w dowolnym momencie, o czym jesteś
                informowany w momencie zbierania zgody. Umożliwiamy Ci wycofanie
                zgody w tak łatwy sposób, w jakim jej udzielono (drogą e-mail).
                Cofnięcie zgody spowoduje wyłącznie skutek w postaci
                nieotrzymywania od nas informacji handlowych oraz newslettera,
                związanych z przedmiotem naszej działalności.
              </li>
            </ul>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>
              §14 Prawa Użytkowników związane z przetwarzaniem danych osobowych
            </PolicySubHeader>
            <PolicyParagraph>
              Serwis gromadzi i przetwarza dane Użytkowników na podstawie:
            </PolicyParagraph>
            <ul>
              <li>
                <b>Prawo dostępu do danych osobowych</b>
                <br />
                Użytkownikom przysługuje prawo uzyskania dostępu do swoich
                danych osobowych, realizowane na żądanie złożone do
                Administratora
              </li>
              <li>
                <b>Prawo do sprostowania danych osobowych</b>
                <br />
                Użytkownikom przysługuje prawo żądania od Administratora
                niezwłocznego sprostowania danych osobowych, które są
                nieprawidłowe lub / oraz uzupełnienia niekompletnych danych
                osobowych, realizowane na żądanie złożone do Administratora
              </li>
              <li>
                <b>Prawo do usunięcia danych osobowych</b>
                <br />
                Użytkownikom przysługuje prawo żądania od Administratora
                niezwłocznego usunięcia danych osobowych, realizowane na żądanie
                złożone do Administratora W przypadku kont użytkowników,
                usunięcie danych polega na anonimizacji danych umożliwiających
                identyfikację Użytkownika. Administrator zastrzega sobie prawo
                wstrzymania realizacji żądania usunięcia danych w celu ochrony
                prawnie uzasadnionego interesu Administratora (np. w gdy
                Użytkownik dopuścił się naruszenia Regulaminu czy dane zostały
                pozyskane wskutek prowadzonej korespondencji). <br />W przypadku
                usługi Newsletter, Użytkownik ma możliwość samodzielnego
                usunięcia swoich danych osobowych korzystając z odnośnika
                umieszczonego w każdej przesyłanej wiadomości e-mail.
              </li>
              <li>
                <b>Prawo do ograniczenia przetwarzania danych osobowych</b>
                <br />
                Użytkownikom przysługuje prawo ograniczenia przetwarzania danych
                osobowych w przypadkach wskazanych w art. 18 RODO, m.in.
                kwestionowania prawidłowość danych osobowych, realizowane na
                żądanie złożone do Administratora
              </li>
              <li>
                <b>Prawo do przenoszenia danych osobowych</b>
                <br />
                Użytkownikom przysługuje prawo uzyskania od Administratora,
                danych osobowych dotyczących Użytkownika w ustrukturyzowanym,
                powszechnie używanym formacie nadającym się do odczytu
                maszynowego, realizowane na żądanie złożone do Administratora
              </li>
              <li>
                <b>
                  Prawo wniesienia sprzeciwu wobec przetwarzania danych
                  osobowych
                </b>
                <br />
                Użytkownikom przysługuje prawo wniesienia sprzeciwu wobec
                przetwarzania jego danych osobowych w przypadkach określonych w
                art. 21 RODO, realizowane na żądanie złożone do Administratora
              </li>
              <li>
                <b>Prawo wniesienia skargi</b>
                <br />
                Użytkownikom przysługuje prawo wniesienia skargi do organu
                nadzorczego zajmującego się ochroną danych osobowych.
              </li>
            </ul>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>§15 Kontakt do Administratora</PolicySubHeader>
            <PolicyParagraph>
              Z Administratorem można skontaktować się w jeden z poniższych
              sposobów
            </PolicyParagraph>
            <ul>
              <li>
                <b>Adres pocztowy</b> - Daily Krudysz S.K.A., ul. Modułowa 3, 05-090 Raszyn
              </li>
              <li>
                <b>Adres poczty elektronicznej</b> - bok@owocedobiura.pl
              </li>
              <li>
                <b>Połączenie telefoniczne</b> - +48 575 236 669
              </li>
              <li>
                <b>Formularz kontaktowy</b> - dostępny pod adresem:
                https://owocedobiura.pl/
              </li>
            </ul>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>§16 Wymagania Serwisu</PolicySubHeader>
            <ul>
              <li>
                Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu
                Użytkownika może spowodować nieprawidłowe działanie niektórych
                funkcji Serwisu.
              </li>
              <li>
                Administrator nie ponosi żadnej odpowiedzialności za
                nieprawidłowo działające funkcje Serwisu w przypadku gdy
                Użytkownik ograniczy w jakikolwiek sposób możliwość zapisywania
                i odczytu plików Cookie.
              </li>
            </ul>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>§17 Linki zewnętrzne</PolicySubHeader>
            <PolicyParagraph>
              W Serwisie - artykułach, postach, wpisach czy komentarzach
              Użytkowników mogą znajdować się odnośniki do witryn zewnętrznych,
              z którymi Właściciel serwisu nie współpracuje. Linki te oraz
              strony lub pliki pod nimi wskazane mogą być niebezpieczne dla
              Twojego Urządzenia lub stanowić zagrożenie bezpieczeństwa Twoich
              danych. Administrator nie ponosi odpowiedzialności za zawartość
              znajdującą się poza Serwisem.
            </PolicyParagraph>
          </PolicySection>

          <PolicySection>
            <PolicySubHeader>
              §17 Zmiany i aktualizacje Polityki prywatności i plików cookies
            </PolicySubHeader>
            <ul className={"numeric"}>
              <li>
                Informujemy, że zastrzegamy sobie prawo zmiany niniejszej
                Polityki prywatności i plików cookies w każdym czasie.
              </li>
              <li>
                Niniejsza Polityka prywatności została przyjęta i obowiązuje od
                dnia 27 czerwca 2023 r.
              </li>
              <li>
                Modyfikacje Polityki prywatności i plików cookies mają na celu
                uwzględnienie zmian w przepisach prawa, praktykach
                Administratora dotyczących postępowania z danymi osobowymi i
                wzmocnienie systemu ochrony danych osobowych.
              </li>
              <li>
                Zmiana nastąpi poprzez opublikowanie nowej Polityki Prywatności
                i plików cookies na naszej stronie internetowej.
              </li>
            </ul>
          </PolicySection>
          <PolicySection>
            <PolicySubHeader>Postanowienia końcowe</PolicySubHeader>
            <ul className={"numeric"}>
              <li>
                W sprawach nie uregulowanych w Polityce Prywatności mają
                zastosowanie przepisy prawa dotyczące przetwarzania Danych
                Osobowych, w tym RODO.
              </li>
            </ul>
          </PolicySection>
        </PolicyContainer>
      </StyledSection>
    </>
  );
}

export default Policy;
