import React from "react";
import styled from "styled-components";
import { theme } from "../theme/config";
import { useTranslation } from "react-i18next";
import { MainSection, Text } from "../theme/shared";
import missionHero from "../assets/images/mission_hero.jpg";

const StyledSection = styled(MainSection)`
  background: ${theme.colors.red.default};
  display: flex;
  flex-direction: row;
  padding: 0;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    flex-direction: column;
    padding: 0;
  }
`;
const TextColumn = styled.div`
  width: 50%;
  padding-top: 7.813rem;
  padding-bottom: 11.24rem;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    width: 100%;
    padding-top: 3.125rem;
    padding-bottom: 6.25rem;
  }
`;

const StyledHeader = styled.h2`
  font-size: 3.5rem;
  font-family: ${theme.fonts.headers};
  color: ${theme.colors.white.default};
  margin-bottom: 50px;
  padding: 0 278px 0 185px;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    padding: 0 2.5rem;
    text-align: left;
    margin-bottom: 2.063rem;
    font-size: 1.438rem;
  }
`;

const StyledText = styled(Text)`
  color: ${theme.colors.white.default};
  line-height: 1.75rem;
  padding: 0 278px 0 185px;
  @media (max-width: ${theme.breakpoints.desktop.max}) {
    padding: 0 2.5rem;
  }
`;

const Image = styled.div`
  width: 50%;
  background: url(${missionHero});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  @media (max-width: ${theme.breakpoints.tablet.max}) {
    width: 100%;
    height: 385px;
  }
  //background-attachment: fixed;
`;

function Mission() {
  const { t } = useTranslation();
  return (
    <StyledSection id={"mission"}>
      <TextColumn>
        <StyledHeader>{t("nasza misja")}</StyledHeader>
        <StyledText>
          {t(
            "Od 13 lat staramy się promować zdrowy tryb życia i pokazać, że dawka witamin w postaci owocu powinna być nieodłącznym elementem każdego dnia. Naszą misję podziela niemal 500 renomowanych firm i korporacji na terenie Trójmiasta i okolic," +
              " które zdecydowały się na współpracę z nami. Naszym priorytetem jest przede wszystkim jakość – owoce i zdrowe produkty pochodzą od sprawdzonych, długoletnich dostawców, a wszystko jest starannie selekcjonowane w noc przed dostawą. "
          )}
          <br/><br/>
          {t("Zamówienia na Owoce do biura realizujemy na terenie Trójmiasta oraz w takich miastach jak Elbląg, Kwidzyn, Lębork, Słupsk, Starogard Gdański, Tczew i wiele innych.")}
        </StyledText>
      </TextColumn>
      <Image />
      {/*<MissionContainer>*/}

      {/*</MissionContainer>*/}
      {/*<Image />*/}
    </StyledSection>
  );
}

export default Mission;
